import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import { strings } from './strings'
import './index.scss'

NoDataToDisplay(Highcharts)

const HighchartsComponent = ({ options, isLoading }) => {
  const chartContainerRef = useRef(null)
  const chartRef = useRef(null)

  useEffect(() => {
    if (!chartContainerRef.current) return

    const chartConfig = {
      ...options,
      lang: strings,
      chart: {
        height: options.series.length ? '' : 100,
        ...options.chart
      },
      credits: { enabled: false }
    }

    if (!chartRef.current) {
      chartRef.current = Highcharts.chart(
        chartContainerRef.current,
        chartConfig
      )
    } else {
      chartRef.current.update(chartConfig, true, true)
    }

    if (chartRef.current) {
      isLoading
        ? chartRef.current.showLoading()
        : chartRef.current.hideLoading()
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
        chartRef.current = null
      }
    }
  }, [options, isLoading])

  return <div ref={chartContainerRef} />
}

export default HighchartsComponent
