import {
  FETCH_CURRENT_DATA,
  CURRENT_DATA_FETCHED,
  CURRENT_MOBILE_DATA_FETCHED,
  FETCH_AVERAGE_DATA,
  AVERAGE_DATA_FETCHED,
  FETCH_DATASET,
  DATASET_FETCHED,
  FETCH_CURRENT_SIMPLE_DASHBOARD_DATA,
  CURRENT_SIMPLE_DASHBOARD_DATA_FETCHED,
  UPDATE_CURRENT_DASHBOARD_DATA,
  UPDATE_CURRENT_MOBILE_DASHBOARD_DATA,
  UPDATE_CURRENT_SIMPLE_DASHBOARD_DATA
} from '@/actions/ts/dashboard'

import { createReducer } from '@/Util/ReduxUtils'
import { parseDataItemDate } from './utils'

export const initialState = {
  data: {},
  dataSeed: null,
  mobileData: {},
  simpleData: {},
  currentDataError: null,
  averageDataError: null,
  datasetError: null,
  isCurrentDataLoading: false,
  isAverageDataLoading: false,
  isDatasetLoading: false,
  isSimpleDataLoading: false
}

const parseStatus = (currentValue, newValue) => {
  if (!isNaN(currentValue)) {
    if (newValue < currentValue) {
      return 'decrease'
    } else if (newValue > currentValue) {
      return 'increase'
    }
  }
  return 'equal'
}

const getSeed = () => {
  return Math.round(Math.random() * 89999 + 10000)
}

const updateData = (oldData, zone, measurement) => {
  let newData = oldData
  if (!newData[zone]) {
    newData[zone] = {
      [measurement]: {}
    }
  } else if (!newData[zone]?.[measurement]) {
    newData[zone][measurement] = {}
  }
  return newData
}

const updateDataAverage = (oldData, zone, measurement, range) => {
  let newData = updateData(oldData, zone, measurement)
  if (!newData[zone][measurement].average) {
    newData[zone][measurement].average = {
      [range]: []
    }
  } else if (!newData[zone][measurement].average[range]) {
    newData[zone][measurement].average[range] = []
  }
  return newData
}

const updateDataset = (oldData, zone, measurement, range, interval) => {
  let newData = updateData(oldData, zone, measurement)
  if (!newData[zone][measurement].dataset) {
    newData[zone][measurement].dataset = {
      [range]: {
        [interval]: []
      }
    }
  } else if (!newData[zone][measurement].dataset[range]) {
    newData[zone][measurement].dataset[range] = {
      [interval]: []
    }
  } else if (!newData[zone][measurement].dataset[range][interval]) {
    newData[zone][measurement].dataset[range][interval] = []
  }
  return newData
}

const handlers = {
  [FETCH_CURRENT_DATA]: state => ({
    ...state,
    currentDataError: null,
    isCurrentDataLoading: true
  }),
  [CURRENT_DATA_FETCHED]: (state, { error, currentAverageMinMax }) => {
    const newData = error
      ? { ...state.data }
      : getParsedData({ ...state.data }, currentAverageMinMax)

    return {
      ...state,
      data: newData,
      dataSeed: getSeed(),
      currentDataError: error ?? null,
      isCurrentDataLoading: false
    }
  },
  [CURRENT_MOBILE_DATA_FETCHED]: (state, { currentAverageMinMax }) => {
    const newData = currentAverageMinMax.reduce((allData, sensorData) => {
      const data = getParsedData({ ...state.mobileData }, sensorData)
      return { ...allData, ...data }
    }, {})

    return {
      ...state,
      mobileData: newData
    }
  },
  [FETCH_AVERAGE_DATA]: state => ({
    ...state,
    dataError: null,
    isAverageDataLoading: true
  }),
  [AVERAGE_DATA_FETCHED]: (state, { error, averageMinMax, params, seed }) => {
    let newData = { ...state.data }

    if (!error) {
      if (averageMinMax?.rows.length > 0) {
        averageMinMax.rows.forEach(
          ([sensor, measurement, max, min, sum, count]) => {
            const value = sum / count
            const { rangeFilter: range } = params
            newData = updateDataAverage(newData, sensor, measurement, range)
            newData[sensor][measurement].average[range] = {
              value: parseFloat(value),
              min: parseFloat(min),
              max: parseFloat(max),
              count: parseFloat(count),
              sum: parseFloat(sum),
              status: parseStatus(
                newData[sensor]?.[measurement]?.average?.value,
                parseFloat(value)
              )
            }
          }
        )
      }
    }

    return {
      ...state,
      data: newData,
      dataSeed: seed,
      averageDataError: error ?? null,
      isAverageDataLoading: false
    }
  },
  [FETCH_DATASET]: state => ({
    ...state,
    datasetError: null,
    isDatasetLoading: true
  }),
  [DATASET_FETCHED]: (state, { datasetAverages, params, seed, timeZone }) => {
    const errors = Object.values(datasetAverages).reduce(
      (errs, zoneAverages) => {
        return zoneAverages.reduce((zoneErrs, { error }) => {
          return error ? [...zoneErrs, error] : zoneErrs
        }, errs)
      },
      []
    )

    let newData = { ...state.data }

    Object.keys(datasetAverages).forEach(zoneId => {
      datasetAverages[zoneId].forEach(({ datasetAverage, error }) => {
        if (datasetAverage && !error) {
          const {
            rangeFilter: range,
            intervalFilter: interval,
            excludeIntervalAndFilter = false
          } = params
          const {
            columns,
            rows,
            measurement,
            sensorId: zoneId
          } = datasetAverage

          newData = updateDataset(newData, zoneId, measurement, range, interval)

          const timestampIndex = columns.indexOf('timestamp')
          const valueIndex = columns.indexOf('average')

          const mappedRows = rows.map(row => ({
            x: parseDataItemDate(row[timestampIndex], timeZone),
            y: parseFloat(row[valueIndex])
          }))

          if (excludeIntervalAndFilter) {
            newData[zoneId][measurement].dataset = mappedRows
          } else {
            newData[zoneId][measurement].dataset[range][interval] = mappedRows
          }
        }
      })
    })

    return {
      ...state,
      data: newData,
      dataSeed: seed,
      datasetErrors: errors && errors.length > 0 ? errors[0] : null,
      isDatasetLoading: false
    }
  },
  [FETCH_CURRENT_SIMPLE_DASHBOARD_DATA]: state => ({
    ...state,
    currentDataError: null,
    isSimpleDataLoading: true
  }),
  [CURRENT_SIMPLE_DASHBOARD_DATA_FETCHED]: (
    state,
    { currentAverageMinMax }
  ) => {
    const data = getParsedData({ ...state.simpleData }, currentAverageMinMax)

    return {
      ...state,
      simpleData: data,
      isSimpleDataLoading: false
    }
  },
  [UPDATE_CURRENT_DASHBOARD_DATA]: (state, { data }) => {
    return {
      ...state,
      data: getUpdatedParsedData({ ...state.data }, data),
      dataSeed: getSeed()
    }
  },
  [UPDATE_CURRENT_MOBILE_DASHBOARD_DATA]: (state, { data }) => {
    return {
      ...state,
      mobileData: getUpdatedParsedData({ ...state.mobileData }, data)
    }
  },
  [UPDATE_CURRENT_SIMPLE_DASHBOARD_DATA]: (state, { data }) => {
    return {
      ...state,
      simpleData: getUpdatedParsedData({ ...state.simpleData }, data)
    }
  }
}

export default createReducer(handlers, initialState)

const getParsedData = (stateData, currentAverageMinMax) => {
  let newData = stateData

  if (currentAverageMinMax?.rows.length > 0) {
    currentAverageMinMax.rows.forEach(
      ([sensor, measurement, value, max, min]) => {
        newData = updateData(newData, sensor, measurement)
        newData[sensor][measurement].current = {
          value: parseFloat(value),
          min: parseFloat(min),
          max: parseFloat(max),
          status: parseStatus(
            newData[sensor]?.[measurement]?.current?.value,
            parseFloat(value)
          )
        }
      }
    )
  }

  return newData
}

const getUpdatedParsedData = (stateData, data) => {
  let newData = stateData
  if (data?.Values.length > 0) {
    data.Values.forEach(({ SensorId, MeasureName, Avg, Max, Min }) => {
      let newValue = parseFloat(Avg)

      newData = updateData(newData, SensorId, MeasureName)

      newData[SensorId][MeasureName].current = {
        value: newValue,
        min: parseFloat(Min),
        max: parseFloat(Max),
        status: parseStatus(
          newData[SensorId]?.[MeasureName]?.current?.value,
          newValue
        )
      }
    })
  }

  return newData
}
