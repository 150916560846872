import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// Queries

export const getDatasetAverage = async params => {
  try {
    const { cacheOperation, ...rest } = params

    const query = /* GraphQL */ `
      query datasetAverageQuery(
        $organizationId: String
        $siteId: String
        $table: String!
        $sensorId: String!
        $measurement: String!
        $fromDate: String!
        $toDate: String!
        $interval: String
        $originFilter: [String]
      ) {
        datasetAverageQuery(
          organizationId: $organizationId
          siteId: $siteId
          table: $table
          sensorId: $sensorId
          measurement: $measurement
          fromDate: $fromDate
          toDate: $toDate
          interval: $interval
          originFilter: $originFilter
        ) {
          rows
          columns
          types
          sensorId
          measurement
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, rest)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id,
      cacheOperation ?? true
    )

    if (error) {
      return {
        datasetAverage: null,
        error: `Data not fetched: ${error}`
      }
    }

    return {
      datasetAverage: data?.datasetAverageQuery,
      error: null
    }
  } catch (err) {
    return { datasetAverage: null, error: err.message }
  }
}

export const getDatasetMobileAverage = async params => {
  try {
    const { cacheOperation, ...rest } = params

    const query = /* GraphQL */ `
      query datasetMobileAverageQuery(
        $organizationId: String
        $siteId: String
        $table: String!
        $sensorId: String!
        $measurements: [String]
        $fromDate: String!
        $toDate: String!
        $interval: String
      ) {
        datasetMobileAverageQuery(
          organizationId: $organizationId
          siteId: $siteId
          table: $table
          sensorId: $sensorId
          measurements: $measurements
          fromDate: $fromDate
          toDate: $toDate
          interval: $interval
        ) {
          rows
          columns
          types
          sensorId
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, rest)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id,
      cacheOperation ?? true
    )

    if (error) {
      return {
        datasetAverage: null,
        error: `Data not fetched: ${error}`
      }
    }

    return {
      datasetAverage: data?.datasetMobileAverageQuery,
      error: null
    }
  } catch (err) {
    return { datasetAverage: null, error: err.message }
  }
}
