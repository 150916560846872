import React from 'react'
import { I18n } from 'aws-amplify'
import Box from '@/primitives/Box'
import Text from '@/primitives/Text'

export const ErrorState = ({ message }) => (
  <Box style={{ margin: '0.8rem 0' }}>
    <Text tone='error' variant='page'>
      {I18n.get('Error loading trial')}: {message}
    </Text>
  </Box>
)
