import {
  findMeasurementName,
  getMeasurementStrings
} from '@/Util/MeasurementUtils'

const baseMetricDefinitions = [
  {
    id: 'temperature',
    icon: 'device_thermostat'
  },
  {
    id: 'par',
    title: 'PAR',
    icon: 'wb_sunny'
  },
  {
    id: 'daily_light_integral',
    icon: 'light_mode'
  },
  {
    id: 'vapour_pressure_deficit',
    title: 'VPD',
    icon: 'water_drop'
  }
]

const advancedMetricDefinitions = [
  {
    id: 'nir',
    icon: 'waves'
  }
]

const soilMetricDefinitions = [
  {
    id: 'soil_temperature',
    icon: 'device_thermostat'
  },
  {
    id: 'soil_moisture',
    icon: 'water_drop'
  }
]

function parseMetrics(measurements, metrics) {
  if (!measurements) return metrics
  return metrics.map(metric => {
    return {
      title:
        getMeasurementStrings(metric.id)?.shortName ||
        findMeasurementName(measurements, metric.id, 'shortName') ||
        metric.id,
      unitLabel: findMeasurementName(measurements, metric.id, 'unit'),
      ...metric
    }
  })
}

export function createParsedMetrics(measurements) {
  return {
    baseEnvMetrics: parseMetrics(measurements, baseMetricDefinitions),
    advancedEnvMetrics: parseMetrics(measurements, advancedMetricDefinitions),
    soilMetrics: parseMetrics(measurements, soilMetricDefinitions)
  }
}
