import { useMemo } from 'react'
import { createParsedMetrics } from '../Dashboard/utils'

export const useMetrics = (measurements, isAdvancedView = false) => {
  const { baseEnvMetrics, advancedEnvMetrics, soilMetrics } = useMemo(() => {
    if (!measurements) {
      return { baseEnvMetrics: [], advancedEnvMetrics: [], soilMetrics: [] }
    }
    return createParsedMetrics(measurements)
  }, [measurements])

  const envMetrics = useMemo(() => {
    return isAdvancedView
      ? [...baseEnvMetrics, ...advancedEnvMetrics]
      : baseEnvMetrics
  }, [baseEnvMetrics, advancedEnvMetrics, isAdvancedView])

  return { envMetrics, soilMetrics }
}
