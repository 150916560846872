export const TRIAL_STATUS = {
  PLANNED: 'planned',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
}

export const STATUS_STYLES = {
  [TRIAL_STATUS.PLANNED]: {
    textColor: 'var(--ctx-theme-color-danger-600)',
    dotColor: 'var(--ctx-theme-color-danger-900)'
  },
  [TRIAL_STATUS.IN_PROGRESS]: {
    textColor: 'var(--ctx-theme-color-info-800)',
    dotColor: 'var(--ctx-theme-color-info-300)'
  },
  [TRIAL_STATUS.COMPLETED]: {
    textColor: 'var(--ctx-theme-color-success-800)',
    dotColor: 'var(--ctx-theme-color-success-300)'
  },
  [TRIAL_STATUS.CANCELLED]: {
    textColor: 'var(--ctx-theme-color-error-800)',
    dotColor: 'var(--ctx-theme-color-error-300)'
  }
}
