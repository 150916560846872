import { Children, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, matchPath } from 'react-router-dom'

import { Box, Flex } from '@/primitives'
import { Group, LocationSelector } from './Assets'

import useDeviceSize from '@/hooks/useDeviceSize'
import ZoneUtils from '@/Util/ZoneUtils'

import { fetchSiteHierarchy } from '@/slices/management/hierarchy'

import {
  resetNavigation,
  setLocationSelector,
  setOptionsRoute,
  setPermissions,
  setSelectedLevel,
  togglePanelLocationSelectorOpen,
  togglePanelPrimaryOpen
} from '@/slices/navigation'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getNavigationLocationSelector,
  getNavigationPanelLocationSelectorOpen,
  getNavigationPanelPrimaryLock,
  getNavigationPanelPrimaryOpen,
  getNavigationPermissions,
  getZone,
  getZonesHierarchy,
  getZonesHierarchyFetched
} from '@/reducers/selectors'

import { getAdminDefaultUrl } from '@/Util/AdminUtils'

import {
  checkUserZonesDashboardPermissions,
  getIsGodMode
} from '@/Util/PermissionUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

import { normalizeRouteSetData, compareLocationSelectors } from './Utils'

import { getReadPermissions } from './permissions'
import Strings from './Strings'

import routes from './Assets/LocationSelector/qualified_routes.json'

import './index.scss'

const Navigation = () => {
  const dispatch = useDispatch()
  // ---------------------------------------------------------- Navigation -- //
  const navigationPanelPrimaryLock = getNavigationPanelPrimaryLock()
  const navigationPanelPrimaryOpen = getNavigationPanelPrimaryOpen()
  const navigationPanelLocationSelectorOpen =
    getNavigationPanelLocationSelectorOpen()
  const navigationPermissions = getNavigationPermissions()
  const locationSelector = getNavigationLocationSelector()

  // --------------------------------------------------------- Params Hook -- //
  const location = useLocation()

  // --------------------------------------------------- Viewport Listener -- //
  const isBelowBreakpoint = useDeviceSize('laptopLarge')

  // ---------------------------------------------------- Permission State -- //
  const coretexUser = getCurrentUser()
  const adminUrl = getAdminDefaultUrl(coretexUser)

  // ---------------------------------------------- LocationSelector State -- //
  const zonesHierarchy = getZonesHierarchy()
  const zonesHierarchyFetched = getZonesHierarchyFetched()
  const zone = getZone()

  const allOrganizations = getCurrentUserOrganizations()

  const [params, setParams] = useState({})

  // ------------------------------------------------------ Event Handlers -- //
  const handleMouse = ({ type }) => {
    if (
      !navigationPanelPrimaryLock &&
      ((type === 'mouseenter' && !navigationPanelPrimaryOpen) ||
        (type === 'mouseleave' && navigationPanelPrimaryOpen))
    ) {
      dispatch(togglePanelPrimaryOpen())

      if (navigationPanelLocationSelectorOpen) {
        dispatch(togglePanelLocationSelectorOpen())
      }
    }
  }

  const handleClick = ({
    target: {
      dataset: { level }
    }
  }) => {
    if (level) {
      dispatch(setSelectedLevel(level))

      if (!navigationPanelPrimaryLock && isBelowBreakpoint) {
        dispatch(togglePanelPrimaryOpen())
      }
    }
  }

  // ----------------------------------------------------- Contextual Data -- //
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const strings = useMemo(() => Strings(orgLabels), [orgLabels])

  // ----------------------------------------------------------- Component -- //

  useEffect(() => () => dispatch(resetNavigation()), [dispatch])

  // --- Initial LocationSelector Side Effect ------------------------------- //

  useEffect(() => {
    let match = null
    for (const route of routes) {
      match = matchPath(location.pathname, route)
      if (match !== null) {
        setParams(match.params)
        break
      } else {
        setParams({})
      }
    }
  }, [location])

  const getOrganization = useCallback(
    organizationId => {
      for (let organization of allOrganizations) {
        if (organization.id === organizationId) {
          return organization
        }
      }
      return null
    },
    [allOrganizations]
  )

  const processHierarchy = useCallback(
    (hierarchy, currentZoneId) => {
      if (
        allOrganizations?.length &&
        Object.keys(hierarchy).length &&
        navigationPermissions
      ) {
        if (params?.zone) {
          const primaryZone = ZoneUtils.getPrimaryZone(params)
          const currentZone = ZoneUtils.getCurrentZone(params)

          let organization = null
          let zones = {
            site: null,
            facility: null,
            room: null,
            zone: null
          }

          if (primaryZone === currentZone) {
            organization = getOrganization(primaryZone)

            if (!organization) {
              const site = ZoneUtils.getZone(hierarchy, primaryZone)
              if (site === null && primaryZone === currentZoneId) {
                dispatch(fetchSiteHierarchy({ zoneId: currentZoneId }))
              } else {
                zones.site = site
              }
            }
          } else {
            const path = params.zone.split('/')
            path.forEach(zoneId => {
              let zone = ZoneUtils.getZone(hierarchy, primaryZone, zoneId)
              if (zone !== null) {
                zones[ZoneUtils.getZoneDepthIdentifier(zone)] = zone
              }
            })
          }

          const nextLocationSelector = new Map()

          if (zones.site) {
            organization = getOrganization(zones.site.organizationId)
          }

          if (organization) {
            nextLocationSelector.set('organization', {
              id: organization.id,
              name: organization.name
            })
          }

          let lastStatus = 'active'
          Object.keys(zones).forEach(zoneKey => {
            if (zones[zoneKey]) {
              const nextStatus =
                lastStatus === 'active' ? zones[zoneKey].status : lastStatus
              nextLocationSelector.set(zoneKey, {
                id: zones[zoneKey].id,
                name: zones[zoneKey].name,
                status: nextStatus
              })
              lastStatus = nextStatus
            }
          })

          if (params?.id && params?.type) {
            const sensorName = ZoneUtils.getSensorName(
              zonesHierarchy,
              primaryZone,
              currentZone,
              params.id
            )
            nextLocationSelector.set('sensor', {
              id: params.id,
              name: sensorName ?? params.id,
              type: params.type,
              status: lastStatus
            })
          }

          const isMatchedLocation = compareLocationSelectors({
            localLocationSelector: nextLocationSelector,
            locationSelector
          })

          if (!isMatchedLocation) {
            dispatch(setLocationSelector(nextLocationSelector))

            const nextSelectedLevel =
              [...nextLocationSelector.keys()].pop() || 'organization'

            if (nextSelectedLevel) {
              dispatch(setSelectedLevel(nextSelectedLevel))
            }
          }
        }

        const payload = normalizeRouteSetData({
          allOrganizations,
          zonesHierarchy: Object.values(hierarchy)
        })

        if (payload) {
          dispatch(setOptionsRoute(payload))
        }
      }
    },
    [
      allOrganizations,
      dispatch,
      getOrganization,
      params,
      locationSelector,
      navigationPermissions
    ]
  )

  useEffect(() => {
    if (zonesHierarchyFetched && Object.keys(zonesHierarchy).length) {
      processHierarchy(zonesHierarchy, zone?.id)
    }
  }, [
    params,
    zone?.id,
    allOrganizations,
    zonesHierarchy,
    zonesHierarchyFetched,
    navigationPermissions,
    processHierarchy
  ])

  // --- User Permissions Side Effect --------------------------------------- //
  useEffect(() => {
    if (coretexUser) {
      const value = {
        ...getReadPermissions(coretexUser),
        godMode: false,
        zone_root: false
      }

      // GodMode
      if (Object.keys(value).length > 0) {
        value.godMode = getIsGodMode(coretexUser)
      }

      // ZONE_ROOT
      const canViewZone = checkUserZonesDashboardPermissions(
        coretexUser,
        'zone',
        ZoneUtils.getPrimaryZone(params)
      )

      value.zone_root = canViewZone
      if (params.zone) {
        value.trial_mode_view = ZoneUtils.getTrialModeViewForSite(
          zonesHierarchy,
          ZoneUtils.getPrimaryZone(params)
        )
      } else {
        value.trial_mode_view = navigationPermissions.trial_mode_view ?? false
      }

      dispatch(setPermissions(value))
    }
  }, [coretexUser, adminUrl, dispatch, params])

  return (
    <aside
      className='Navigation'
      onMouseEnter={handleMouse}
      onMouseLeave={handleMouse}
      onClick={handleClick}
    >
      <Flex as='nav' direction='column' wrap='nowrap'>
        {strings && <LocationSelector strings={strings} />}
        <Box className='LocationSelectorMenu'>
          {locationSelector?.size > 0 &&
            Children.toArray(
              [...locationSelector.entries()].map(([level]) => (
                <Group
                  level={level}
                  locationSelector={locationSelector}
                  permissions={navigationPermissions}
                  strings={strings}
                />
              ))
            )}
          <Group
            level={'admin'}
            locationSelector={locationSelector}
            permissions={navigationPermissions}
            strings={strings}
          />
        </Box>
      </Flex>
    </aside>
  )
}

export default Navigation
