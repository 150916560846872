export const getTrialState = state => {
  return state.rootReducer?.managementTrialsReducer
}

export const formatDate = timestamp => {
  if (!timestamp) return null
  return new Date(timestamp).toLocaleDateString(navigator.language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
}
