import React from 'react'
import Card from '@/primitives/Card'
import FlexV2 from '@/primitives/FlexV2'
import Image from '@/primitives/Image'
import Text from '@/primitives/Text'
import Slot from '@/primitives/Slot'
import Box from '@/primitives/Box'

const SectionCard = ({ section, color, showLogo, children }) => (
  <Card className={`section-card section-card--${color}`}>
    <Slot name='header'>
      <FlexV2
        alignMainAxis='space-between'
        alignCrossAxis='flex-end'
        className='section-card__header'
      >
        <Text size={500} fontWeight={600}>
          {section}
        </Text>
        {showLogo && (
          <Image
            src='/iyris.svg'
            alt='Iyris Logo'
            className='section-card__logo'
          />
        )}
      </FlexV2>
    </Slot>
    <Slot name='body'>
      <Box className='section-card__content'>{children}</Box>
    </Slot>
  </Card>
)

export default SectionCard
