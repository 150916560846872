import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import FlexV2 from '@/primitives/FlexV2'
import Button from '@/primitives/Button'
import Text from '@/primitives/Text'
import Slot from '@/primitives/Slot'
import Dialog from '@/elements/Dialog'
import { getZonesHierarchy } from '@/reducers/selectors'
import { setEnvSensors, setSoilSensors } from '@/slices/management/trial'
import { getTrialState } from '../../utils'
import { findZoneById, getSensorsFromZone } from './utils'
import strings from '../../Strings'
import Icon from '@/primitives/Icon'

const SensorFilterDialog = ({
  open,
  onOpenChange,
  sectionAId,
  sectionBId,
  dialogMode
}) => {
  const dispatch = useDispatch()
  const zonesHierarchy = getZonesHierarchy()
  const { envSensorsA, envSensorsB, soilSensorsA, soilSensorsB, currentTrial } =
    useSelector(getTrialState)

  const [localSelectedA, setLocalSelectedA] = useState(
    dialogMode === 'envirosense' ? envSensorsA : soilSensorsA
  )
  const [localSelectedB, setLocalSelectedB] = useState(
    dialogMode === 'envirosense' ? envSensorsB : soilSensorsB
  )

  const [isDropdownAOpen, setIsDropdownAOpen] = useState(false)
  const [isDropdownBOpen, setIsDropdownBOpen] = useState(false)

  useEffect(() => {
    if (dialogMode === 'envirosense') {
      setLocalSelectedA(envSensorsA)
      setLocalSelectedB(envSensorsB)
    } else {
      setLocalSelectedA(soilSensorsA)
      setLocalSelectedB(soilSensorsB)
    }
  }, [dialogMode, envSensorsA, envSensorsB, soilSensorsA, soilSensorsB])

  const zoneHierarchy = zonesHierarchy[currentTrial?.zone?.id]
  const zoneA = findZoneById(sectionAId, zoneHierarchy)
  const zoneB = findZoneById(sectionBId, zoneHierarchy)

  const sensorsA = getSensorsFromZone(zoneA, dialogMode)
  const sensorsB = getSensorsFromZone(zoneB, dialogMode)

  const handleApply = () => {
    if (dialogMode === 'envirosense') {
      dispatch(
        setEnvSensors({
          sectionA: localSelectedA,
          sectionB: localSelectedB
        })
      )
    } else {
      dispatch(
        setSoilSensors({
          sectionA: localSelectedA,
          sectionB: localSelectedB
        })
      )
    }
    onOpenChange(false)
  }

  const handleCancel = () => {
    if (dialogMode === 'envirosense') {
      setLocalSelectedA(envSensorsA)
      setLocalSelectedB(envSensorsB)
    } else {
      setLocalSelectedA(soilSensorsA)
      setLocalSelectedB(soilSensorsB)
    }
    onOpenChange(false)
  }

  const handleChange = setFunction => selectedOptions =>
    setFunction(selectedOptions?.map(option => option.value) || [])

  const customStyles = {
    menu: provided => ({
      ...provided,
      position: 'relative',
      margin: 0,
      padding: 0,
      boxShadow: 'none',
      border: 'none'
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 'none',
      padding: 0
    }),
    placeholder: provided => ({
      ...provided,
      zIndex: '99999'
    })
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange} type='modal'>
      <Slot name='title'>
        <FlexV2 alignCrossAxis='center' axisGap={200}>
          <Icon
            name={dialogMode === 'envirosense' ? 'thermostat' : 'water_drop'}
            size={400}
          />
          <Text size={400} fontWeight={600}>
            {`${strings[dialogMode]} ${strings.sensorFilter}`}
          </Text>
        </FlexV2>
      </Slot>
      <Slot name='content'>
        <div
          style={{
            minWidth: '300px',
            padding: '16px',
            transition: 'height 0.2s ease'
          }}
        >
          <FlexV2 direction='column' axisGap={400}>
            <FlexV2 direction='column' axisGap={200}>
              <Text size={300}>
                {`${zoneA?.name || strings.sectionA} ${strings.zoneSensors}`}
              </Text>
              <Select
                isMulti
                value={sensorsA.filter(sensor =>
                  localSelectedA.includes(sensor.value)
                )}
                onChange={handleChange(setLocalSelectedA)}
                options={sensorsA}
                closeMenuOnSelect={false}
                placeholder={strings.selectSensors}
                styles={customStyles}
                onMenuOpen={() => setIsDropdownAOpen(true)}
                onMenuClose={() => setIsDropdownAOpen(false)}
                menuIsOpen={isDropdownAOpen}
              />
            </FlexV2>

            <FlexV2 direction='column' axisGap={200}>
              <Text size={300}>
                {`${zoneB?.name || strings.sectionB} ${strings.zoneSensors}`}
              </Text>
              <Select
                isMulti
                value={sensorsB.filter(sensor =>
                  localSelectedB.includes(sensor.value)
                )}
                onChange={handleChange(setLocalSelectedB)}
                options={sensorsB}
                closeMenuOnSelect={false}
                placeholder={strings.selectSensors}
                styles={customStyles}
                onMenuOpen={() => setIsDropdownBOpen(true)}
                onMenuClose={() => setIsDropdownBOpen(false)}
                menuIsOpen={isDropdownBOpen}
              />
            </FlexV2>
          </FlexV2>
        </div>
      </Slot>
      <Slot name='actions'>
        <div>
          <FlexV2 alignMainAxis='flex-end' axisGap={200}>
            <Button variant='outline' onClick={handleCancel}>
              {strings.cancel}
            </Button>
            <Button variant='primary' onClick={handleApply}>
              {strings.apply}
            </Button>
          </FlexV2>
        </div>
      </Slot>
    </Dialog>
  )
}

export default SensorFilterDialog
