import { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nanoid } from 'nanoid'
import { fetchDataset } from '@/actions/ts/dashboard'
import { requiredVectorMeasurements } from '@/Util/MeasurementUtils'
import { getTrialState } from '../utils'

export const useTrialData = (currentTrial, envMetrics, soilMetrics) => {
  const dispatch = useDispatch()
  const { envSensorsA, envSensorsB, soilSensorsA, soilSensorsB } =
    useSelector(getTrialState)

  const envMeasurementsToFetch = useMemo(() => {
    const metricIds = new Set(envMetrics.map(m => m.id))
    Object.entries(requiredVectorMeasurements).forEach(
      ([metricId, dependencies]) => {
        if (metricIds.has(metricId)) {
          metricIds.delete(metricId)
          dependencies.forEach(dep => metricIds.add(dep))
        }
      }
    )
    return Array.from(metricIds)
  }, [envMetrics])

  const soilMeasurementsToFetch = useMemo(() => {
    const metricIds = new Set(soilMetrics.map(m => m.id))
    Object.entries(requiredVectorMeasurements).forEach(
      ([metricId, dependencies]) => {
        if (metricIds.has(metricId)) {
          metricIds.delete(metricId)
          dependencies.forEach(dep => metricIds.add(dep))
        }
      }
    )
    return Array.from(metricIds)
  }, [soilMetrics])

  const fetchTrialDataForRoom = useCallback(
    (deviceType = null, roomId, sensors) => {
      if (!currentTrial || !roomId || !sensors) return

      const toDate =
        currentTrial.endDate ||
        new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .split('T')[0]

      const measurements =
        deviceType === 'envirosense'
          ? envMeasurementsToFetch
          : deviceType === 'soilsense'
          ? soilMeasurementsToFetch
          : [...envMeasurementsToFetch, ...soilMeasurementsToFetch]

      const params = {
        table: currentTrial.zone.id,
        zones: [roomId],
        originFilter: sensors,
        measurements,
        fromDate: currentTrial.startDate,
        toDate,
        interval: '1h',
        excludeIntervalAndFilter: true
      }

      dispatch(fetchDataset(params, nanoid(5)))
    },
    [currentTrial, envMeasurementsToFetch, soilMeasurementsToFetch, dispatch]
  )

  const fetchTrialData = useCallback(
    (deviceType = null) => {
      if (!currentTrial) return

      if (!deviceType || deviceType === 'envirosense') {
        fetchTrialDataForRoom('envirosense', currentTrial.roomA.id, envSensorsA)
        fetchTrialDataForRoom('envirosense', currentTrial.roomB.id, envSensorsB)
      }

      if (!deviceType || deviceType === 'soilsense') {
        fetchTrialDataForRoom('soilsense', currentTrial.roomA.id, soilSensorsA)
        fetchTrialDataForRoom('soilsense', currentTrial.roomB.id, soilSensorsB)
      }
    },
    [
      currentTrial,
      fetchTrialDataForRoom,
      envSensorsA,
      envSensorsB,
      soilSensorsA,
      soilSensorsB
    ]
  )

  useEffect(() => {
    if (currentTrial) {
      fetchTrialData()
    }
  }, [currentTrial])

  useEffect(() => {
    if (currentTrial) {
      fetchTrialDataForRoom('envirosense', currentTrial.roomA.id, envSensorsA)
    }
  }, [envSensorsA])

  useEffect(() => {
    if (currentTrial) {
      fetchTrialDataForRoom('envirosense', currentTrial.roomB.id, envSensorsB)
    }
  }, [envSensorsB])

  useEffect(() => {
    if (currentTrial) {
      fetchTrialDataForRoom('soilsense', currentTrial.roomA.id, soilSensorsA)
    }
  }, [soilSensorsA])

  useEffect(() => {
    if (currentTrial) {
      fetchTrialDataForRoom('soilsense', currentTrial.roomB.id, soilSensorsB)
    }
  }, [soilSensorsB])

  return { fetchTrialData, fetchTrialDataForRoom }
}
