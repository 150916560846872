import React from 'react'
import Card from '@/primitives/Card'
import FlexV2 from '@/primitives/FlexV2'
import Slot from '@/primitives/Slot'
import Text from '@/primitives/Text'
import InfoRow from './InfoRow'
import strings from '@/components/TrialModeView/Strings'
import { I18n } from 'aws-amplify'
import { formatDate } from '@/components/TrialModeView/utils'

const CropDetails = ({ crop }) => {
  if (!crop) return null

  const cropFields = [
    { label: strings.produce, value: crop.produce },
    { label: strings.variety, value: crop.variety },
    { label: strings.status, value: I18n.get(crop.status) },
    {
      label: strings.plantingArea,
      value: crop.plantingArea && `${crop.plantingArea} m²`
    },
    {
      label: strings.hydrationMethod,
      value: crop.hydrationMethod && I18n.get(crop.hydrationMethod)
    },
    { label: strings.seedQuantity, value: crop.seedQuantity },
    {
      label: strings.estimatedSowDate,
      value: crop.estimatedSowDate && formatDate(crop.estimatedSowDate)
    },
    { label: strings.sowDate, value: crop.sowDate && formatDate(crop.sowDate) },
    {
      label: strings.estimatedTransplantDate,
      value:
        crop.estimatedTransplantDate && formatDate(crop.estimatedTransplantDate)
    },
    {
      label: strings.transplantDate,
      value: crop.transplantDate && formatDate(crop.transplantDate)
    },
    {
      label: strings.estimatedHarvestStartDate,
      value:
        crop.estimatedHarvestStartDate &&
        formatDate(crop.estimatedHarvestStartDate)
    },
    {
      label: strings.harvestStartDate,
      value: crop.harvestStartDate && formatDate(crop.harvestStartDate)
    },
    {
      label: strings.estimatedHarvestEndDate,
      value:
        crop.estimatedHarvestEndDate && formatDate(crop.estimatedHarvestEndDate)
    },
    {
      label: strings.harvestEndDate,
      value: crop.harvestEndDate && formatDate(crop.harvestEndDate)
    }
  ]

  return (
    <Card className='crop-card'>
      <Slot name='header'>
        <Text size={500} fontWeight={600}>
          {strings.cropDetails}
        </Text>
      </Slot>
      <Slot name='body'>
        <FlexV2 direction='column' axisGap={200}>
          {cropFields.map(
            ({ label, value }) =>
              value && <InfoRow key={label} label={label} value={value} />
          )}
        </FlexV2>
      </Slot>
    </Card>
  )
}

export default CropDetails
