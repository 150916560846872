import React from 'react'
import FlexV2 from '@/primitives/FlexV2'
import Text from '@/primitives/Text'

const InfoRow = ({ label, value }) => (
  <FlexV2 axisGap={200}>
    <Text fontWeight={600}>{label}:</Text>
    <Text>{value}</Text>
  </FlexV2>
)

export default InfoRow
