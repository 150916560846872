import React, { useEffect } from 'react'
import FlexV2 from '@/primitives/FlexV2'
import TrialModeViewDashboard from './Dashboard'
import './index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTrial, fetchTrials } from '@/slices/management/trial'
import { showBanner } from '@/slices/util'
import { getTrialState } from './utils'
import { useParams } from 'react-router-dom'

export default function TrialModeView() {
  const dispatch = useDispatch()
  const { trials, error } = useSelector(getTrialState)

  const { zone } = useParams()

  useEffect(() => {
    dispatch(fetchTrials({ zoneId: zone }))
  }, [dispatch])

  useEffect(() => {
    if (trials.length > 0) {
      // When we have trials, select the first one
      // In the future, this will be replaced with proper trial selection
      dispatch(fetchTrial({ id: trials[0].id }))
    }
  }, [trials, dispatch])

  useEffect(() => {
    if (error) {
      const errorDetails = {
        show: true,
        message: error,
        type: 'error'
      }
      dispatch(showBanner(errorDetails))
    }
  }, [dispatch, error])

  return (
    <FlexV2 direction='column' axisGap={400} className='trial-mode-view'>
      <TrialModeViewDashboard />
    </FlexV2>
  )
}
