import { useSelector } from 'react-redux'

const getStateWithSelector = (reducer, value) => {
  return useSelector(state => state.rootReducer[reducer][value])
}

// Greengrass

export const getDeviceUpdatesStarted = () => {
  return getStateWithSelector('greengrassReducer', 'updatesStarted')
}

export const getDeviceUpdatesLoading = () => {
  return getStateWithSelector('greengrassReducer', 'loading')
}

// Timestream

export const getCurrentAveMinMax = () => {
  return getStateWithSelector('tsCurrentReducer', 'currentAverageMinMax')
}

export const getTimeframeAveMinMax = () => {
  return getStateWithSelector('tsTimeframeReducer', 'timeframeAverageMinMax')
}

export const getTimeframeGraphAverages = () => {
  return getStateWithSelector('tsTimeframeReducer', 'timeframeGraphAverages')
}

export const getTimeframeGraphAveragesError = () => {
  return getStateWithSelector(
    'tsTimeframeReducer',
    'timeframeGraphAveragesError'
  )
}

export const getTimeframeGraphAveragesIsLoading = () => {
  return getStateWithSelector(
    'tsTimeframeReducer',
    'timeframeGraphAveragesIsLoading'
  )
}

// Dashboard

export const getDashboardData = () => {
  return getStateWithSelector('dashboardReducer', 'data')
}

export const getDashboardDataSeed = () => {
  return getStateWithSelector('dashboardReducer', 'dataSeed')
}

export const getMobileDashboardData = () => {
  return getStateWithSelector('dashboardReducer', 'mobileData')
}

export const getSimpleDashboardData = () => {
  return getStateWithSelector('dashboardReducer', 'simpleData')
}

export const getCurrentDataLoading = () => {
  return getStateWithSelector('dashboardReducer', 'isCurrentDataLoading')
}

export const getAverageDataLoading = () => {
  return getStateWithSelector('dashboardReducer', 'isAverageDataLoading')
}

export const getDatasetLoading = () => {
  return getStateWithSelector('dashboardReducer', 'isDatasetLoading')
}

export const getSimpleDataLoading = () => {
  return getStateWithSelector('dashboardReducer', 'isSimpleDataLoading')
}

/*
 * Operations
 */

export const getDataboardHarvests = () => {
  return getStateWithSelector('operationsReducer', 'databoardHarvests')
}

export const getDataboardHarvestArgs = () => {
  return getStateWithSelector('operationsReducer', 'databoardHarvestArgs')
}

export const getDataboardCrops = () => {
  return getStateWithSelector('operationsReducer', 'databoardCrops')
}

export const getDataboardCropArgs = () => {
  return getStateWithSelector('operationsReducer', 'databoardCropArgs')
}

export const getDataboardSitesHierarchies = () => {
  return getStateWithSelector('operationsReducer', 'sitesHierarchies')
}

export const getDataboardLoading = () => {
  return getStateWithSelector('operationsReducer', 'loading')
}

export const getOperationsFormState = () => {
  return getStateWithSelector('operationsReducer', 'formState')
}

export const getOperationsPlan = () => {
  return getStateWithSelector('operationsReducer', 'plan')
}

export const getOperationsPlans = () => {
  return getStateWithSelector('operationsReducer', 'plans')
}

export const getOperationsPlansCount = () => {
  return getStateWithSelector('operationsReducer', 'plansCount')
}

export const getOperationsAllPlans = () => {
  return getStateWithSelector('operationsReducer', 'allPlans')
}

export const getOperationsHarvest = () => {
  return getStateWithSelector('operationsReducer', 'harvest')
}

export const getOperationsHarvests = () => {
  return getStateWithSelector('operationsReducer', 'harvests')
}

export const getOperationsHarvestsCount = () => {
  return getStateWithSelector('operationsReducer', 'harvestsCount')
}

export const getOperationsAllHarvests = () => {
  return getStateWithSelector('operationsReducer', 'allHarvests')
}

export const getOperationsBranch = () => {
  return getStateWithSelector('operationsReducer', 'branch')
}

export const getOperationsBranches = () => {
  return getStateWithSelector('operationsReducer', 'branches')
}

export const getOperationsBranchesCount = () => {
  return getStateWithSelector('operationsReducer', 'branchesCount')
}

export const getOperationsCustomer = () => {
  return getStateWithSelector('operationsReducer', 'customer')
}

export const getOperationsCustomers = () => {
  return getStateWithSelector('operationsReducer', 'customers')
}

export const getOperationsCustomersCount = () => {
  return getStateWithSelector('operationsReducer', 'customersCount')
}

export const getOperationsSale = () => {
  return getStateWithSelector('operationsReducer', 'sale')
}

export const getOperationsSales = () => {
  return getStateWithSelector('operationsReducer', 'sales')
}

export const getOperationsSalesCount = () => {
  return getStateWithSelector('operationsReducer', 'salesCount')
}

export const getOperationsWaste = () => {
  return getStateWithSelector('operationsReducer', 'waste')
}

export const getOperationsWastes = () => {
  return getStateWithSelector('operationsReducer', 'wastes')
}

export const getOperationsWastesCount = () => {
  return getStateWithSelector('operationsReducer', 'wastesCount')
}

export const getOperationsReturn = () => {
  return getStateWithSelector('operationsReducer', 'return')
}

export const getOperationsReturns = () => {
  return getStateWithSelector('operationsReducer', 'returns')
}

export const getOperationsReturnsCount = () => {
  return getStateWithSelector('operationsReducer', 'returnsCount')
}

export const getOperationsInventoryCalibrations = () => {
  return getStateWithSelector('operationsReducer', 'inventoryCalibrations')
}

export const getOperationsInventoryCalibrationsCount = () => {
  return getStateWithSelector('operationsReducer', 'inventoryCalibrationsCount')
}

export const getOperationsInventoryTransfer = () => {
  return getStateWithSelector('operationsReducer', 'inventoryTransfer')
}

export const getOperationsInventoryTransfers = () => {
  return getStateWithSelector('operationsReducer', 'inventoryTransfers')
}

export const getOperationsInventoryTransfersCount = () => {
  return getStateWithSelector('operationsReducer', 'inventoryTransfersCount')
}

export const getOperationsInventory = () => {
  return getStateWithSelector('operationsReducer', 'inventory')
}

export const getOperationsInventories = () => {
  return getStateWithSelector('operationsReducer', 'inventories')
}

export const getOperationsInventoriesCount = () => {
  return getStateWithSelector('operationsReducer', 'inventoriesCount')
}

export const getOperationsSaleItems = () => {
  return getStateWithSelector('operationsReducer', 'saleItems')
}

export const getOperationsUnitPrice = () => {
  return getStateWithSelector('operationsReducer', 'unitPrice')
}

export const getOperationsUnitPrices = () => {
  return getStateWithSelector('operationsReducer', 'unitPrices')
}

export const getOperationsUnitPricesCount = () => {
  return getStateWithSelector('operationsReducer', 'unitPricesCount')
}

export const getOperationsAllProduces = () => {
  return getStateWithSelector('operationsReducer', 'allProduces')
}

export const getOperationsProduces = () => {
  return getStateWithSelector('operationsReducer', 'produces')
}

export const getOperationsProducesCount = () => {
  return getStateWithSelector('operationsReducer', 'producesCount')
}

export const getOperationsProduce = () => {
  return getStateWithSelector('operationsReducer', 'produce')
}

export const getOperationsAllGrades = () => {
  return getStateWithSelector('operationsReducer', 'allGrades')
}

export const getOperationsGrades = () => {
  return getStateWithSelector('operationsReducer', 'grades')
}

export const getOperationsGradesCount = () => {
  return getStateWithSelector('operationsReducer', 'gradesCount')
}

export const getOperationsGrade = () => {
  return getStateWithSelector('operationsReducer', 'grade')
}

export const getOperationsAllVarieties = () => {
  return getStateWithSelector('operationsReducer', 'allVarieties')
}

export const getOperationsVarieties = () => {
  return getStateWithSelector('operationsReducer', 'varieties')
}

export const getOperationsVarietiesCount = () => {
  return getStateWithSelector('operationsReducer', 'varietiesCount')
}

export const getOperationsVariety = () => {
  return getStateWithSelector('operationsReducer', 'variety')
}

export const getOperationsAllCurrencies = () => {
  return getStateWithSelector('operationsReducer', 'allCurrencies')
}

export const getOperationsCurrencies = () => {
  return getStateWithSelector('operationsReducer', 'currencies')
}

export const getOperationsCurrenciesCount = () => {
  return getStateWithSelector('operationsReducer', 'currenciesCount')
}

export const getOperationsCurrency = () => {
  return getStateWithSelector('operationsReducer', 'currency')
}

export const getOperationsAllCountries = () => {
  return getStateWithSelector('operationsReducer', 'allCountries')
}

export const getOperationsCountries = () => {
  return getStateWithSelector('operationsReducer', 'countries')
}

export const getOperationsCountriesCount = () => {
  return getStateWithSelector('operationsReducer', 'countriesCount')
}

export const getOperationsRegion = () => {
  return getStateWithSelector('operationsReducer', 'region')
}

export const getOperationsAllRegions = () => {
  return getStateWithSelector('operationsReducer', 'allRegions')
}

export const getOperationsRegions = () => {
  return getStateWithSelector('operationsReducer', 'regions')
}

export const getOperationsRegionsCount = () => {
  return getStateWithSelector('operationsReducer', 'regionsCount')
}

export const getOperationsCountry = () => {
  return getStateWithSelector('operationsReducer', 'country')
}

export const getOperationsAllCities = () => {
  return getStateWithSelector('operationsReducer', 'allCities')
}

export const getOperationsCities = () => {
  return getStateWithSelector('operationsReducer', 'cities')
}

export const getOperationsCitiesCount = () => {
  return getStateWithSelector('operationsReducer', 'citiesCount')
}

export const getOperationsCity = () => {
  return getStateWithSelector('operationsReducer', 'city')
}

export const getOperationsAllAreas = () => {
  return getStateWithSelector('operationsReducer', 'allAreas')
}

export const getOperationsAreas = () => {
  return getStateWithSelector('operationsReducer', 'areas')
}

export const getOperationsAreasCount = () => {
  return getStateWithSelector('operationsReducer', 'areasCount')
}

export const getOperationsArea = () => {
  return getStateWithSelector('operationsReducer', 'area')
}

export const getOperationsAllPackageUnits = () => {
  return getStateWithSelector('operationsReducer', 'allPackageUnits')
}

export const getOperationsPackageUnits = () => {
  return getStateWithSelector('operationsReducer', 'packageUnits')
}

export const getOperationsPackageUnitsCount = () => {
  return getStateWithSelector('operationsReducer', 'packageUnitsCount')
}

export const getOperationsPackageUnit = () => {
  return getStateWithSelector('operationsReducer', 'packageUnit')
}

export const getOperationsAllPaymentMethods = () => {
  return getStateWithSelector('operationsReducer', 'allPaymentMethods')
}

export const getOperationsPaymentMethods = () => {
  return getStateWithSelector('operationsReducer', 'paymentMethods')
}

export const getOperationsPaymentMethodsCount = () => {
  return getStateWithSelector('operationsReducer', 'paymentMethodsCount')
}

export const getOperationsPaymentMethod = () => {
  return getStateWithSelector('operationsReducer', 'paymentMethod')
}

export const getOperationsAllPaymentTerms = () => {
  return getStateWithSelector('operationsReducer', 'allPaymentTerms')
}

export const getOperationsPaymentTerms = () => {
  return getStateWithSelector('operationsReducer', 'paymentTerms')
}

export const getOperationsPaymentTermsCount = () => {
  return getStateWithSelector('operationsReducer', 'paymentTermsCount')
}

export const getOperationsPaymentTerm = () => {
  return getStateWithSelector('operationsReducer', 'paymentTerm')
}

export const getOperationsAllShippingMethods = () => {
  return getStateWithSelector('operationsReducer', 'allShippingMethods')
}

export const getOperationsShippingMethods = () => {
  return getStateWithSelector('operationsReducer', 'shippingMethods')
}

export const getOperationsShippingMethodsCount = () => {
  return getStateWithSelector('operationsReducer', 'shippingMethodsCount')
}

export const getOperationsShippingMethod = () => {
  return getStateWithSelector('operationsReducer', 'shippingMethod')
}

export const getOperationsAllStorageFacilities = () => {
  return getStateWithSelector('operationsReducer', 'allStorageFacilities')
}

export const getOperationsStorageFacilities = () => {
  return getStateWithSelector('operationsReducer', 'storageFacilities')
}

export const getOperationsStorageFacilitiesCount = () => {
  return getStateWithSelector('operationsReducer', 'storageFacilitiesCount')
}

export const getOperationsStorageFacility = () => {
  return getStateWithSelector('operationsReducer', 'storageFacility')
}

export const getOperationsFertilizerInventories = () => {
  return getStateWithSelector('operationsReducer', 'fertilizerInventories')
}

export const getOperationsFertilizerInventoriesCount = () => {
  return getStateWithSelector('operationsReducer', 'fertilizerInventoriesCount')
}
export const getOperationsFertilizerInventory = () => {
  return getStateWithSelector('operationsReducer', 'fertilizerInventory')
}

export const getOperationsAllProduceThresholds = () => {
  return getStateWithSelector(
    'operationsProduceThresholdReducer',
    'allProduceThresholds'
  )
}

export const getOperationsProduceThresholds = () => {
  return getStateWithSelector(
    'operationsProduceThresholdReducer',
    'produceThresholds'
  )
}

export const getOperationsProduceThresholdsCount = () => {
  return getStateWithSelector(
    'operationsProduceThresholdReducer',
    'produceThresholdsCount'
  )
}

export const getOperationsProduceThreshold = () => {
  return getStateWithSelector(
    'operationsProduceThresholdReducer',
    'produceThreshold'
  )
}

export const getOperationsProduceThresholdLoading = () => {
  return getStateWithSelector('operationsProduceThresholdReducer', 'loading')
}

export const getOperationsProduceThresholdError = () => {
  return getStateWithSelector('operationsProduceThresholdReducer', 'error')
}

export const getOperationsProduceThresholdMessages = () => {
  return getStateWithSelector(
    'operationsProduceThresholdMessageReducer',
    'produceThresholdMessages'
  )
}

export const getOperationsProduceThresholdMessagesCount = () => {
  return getStateWithSelector(
    'operationsProduceThresholdMessageReducer',
    'produceThresholdMessagesCount'
  )
}

export const getOperationsProduceThresholdMessage = () => {
  return getStateWithSelector(
    'operationsProduceThresholdMessageReducer',
    'produceThresholdMessage'
  )
}

export const getOperationsProduceThresholdMessageLoading = () => {
  return getStateWithSelector(
    'operationsProduceThresholdMessageReducer',
    'loading'
  )
}

export const getOperationsProduceThresholdMessageError = () => {
  return getStateWithSelector(
    'operationsProduceThresholdMessageReducer',
    'error'
  )
}

export const getOperationsFertilizerInventoryCalibrations = () => {
  return getStateWithSelector(
    'operationsReducer',
    'fertilizerInventoryCalibrations'
  )
}

export const getOperationsFertilizerInventoryCalibrationsCount = () => {
  return getStateWithSelector(
    'operationsReducer',
    'fertilizerInventoryCalibrationsCount'
  )
}

export const getOperationsFertilizerInventoryTransfer = () => {
  return getStateWithSelector(
    'operationsReducer',
    'fertilizerInventoryTransfer'
  )
}

export const getOperationsFertilizerInventoryTransfers = () => {
  return getStateWithSelector(
    'operationsReducer',
    'fertilizerInventoryTransfers'
  )
}

export const getOperationsFertilizerInventoryTransfersCount = () => {
  return getStateWithSelector(
    'operationsReducer',
    'fertilizerInventoryTransfersCount'
  )
}

export const getOperationsFertilizerPurchase = () => {
  return getStateWithSelector('operationsReducer', 'fertilizerPurchase')
}

export const getOperationsFertilizerPurchases = () => {
  return getStateWithSelector('operationsReducer', 'fertilizerPurchases')
}

export const getOperationsFertilizerPurchasesCount = () => {
  return getStateWithSelector('operationsReducer', 'fertilizerPurchasesCount')
}

export const getOperationsPackageUnitInventories = () => {
  return getStateWithSelector('operationsReducer', 'packageUnitInventories')
}

export const getOperationsPackageUnitInventoriesCount = () => {
  return getStateWithSelector(
    'operationsReducer',
    'packageUnitInventoriesCount'
  )
}

export const getOperationsPackageUnitInventory = () => {
  return getStateWithSelector('operationsReducer', 'packageUnitInventory')
}

export const getOperationsPackageUnitInventoryCalibrations = () => {
  return getStateWithSelector(
    'operationsReducer',
    'packageUnitInventoryCalibrations'
  )
}

export const getOperationsPackageUnitInventoryCalibrationsCount = () => {
  return getStateWithSelector(
    'operationsReducer',
    'packageUnitInventoryCalibrationsCount'
  )
}

export const getOperationsPackageUnitInventoryTransfer = () => {
  return getStateWithSelector(
    'operationsReducer',
    'packageUnitInventoryTransfer'
  )
}

export const getOperationsPackageUnitInventoryTransfers = () => {
  return getStateWithSelector(
    'operationsReducer',
    'packageUnitInventoryTransfers'
  )
}

export const getOperationsPackageUnitInventoryTransfersCount = () => {
  return getStateWithSelector(
    'operationsReducer',
    'packageUnitInventoryTransfersCount'
  )
}

export const getOperationsPackageUnitPurchase = () => {
  return getStateWithSelector('operationsReducer', 'packageUnitPurchase')
}

export const getOperationsPackageUnitPurchases = () => {
  return getStateWithSelector('operationsReducer', 'packageUnitPurchases')
}

export const getOperationsPackageUnitPurchasesCount = () => {
  return getStateWithSelector('operationsReducer', 'packageUnitPurchasesCount')
}

export const getOperationsProducts = () => {
  return getStateWithSelector('operationsReducer', 'products')
}

export const getOperationsProductsCount = () => {
  return getStateWithSelector('operationsReducer', 'productsCount')
}

export const getOperationsProduct = () => {
  return getStateWithSelector('operationsReducer', 'product')
}

export const getOperationsCrops = () => {
  return getStateWithSelector('operationsReducer', 'crops')
}

export const getOperationsCropsCount = () => {
  return getStateWithSelector('operationsReducer', 'cropsCount')
}

export const getOperationsAllCrops = () => {
  return getStateWithSelector('operationsReducer', 'allCrops')
}

export const getOperationsCrop = () => {
  return getStateWithSelector('operationsReducer', 'crop')
}

export const getOperationsSuppliers = () => {
  return getStateWithSelector('operationsReducer', 'suppliers')
}

export const getOperationsSuppliersCount = () => {
  return getStateWithSelector('operationsReducer', 'suppliersCount')
}

export const getOperationsSupplier = () => {
  return getStateWithSelector('operationsReducer', 'supplier')
}

export const getOperationsLoading = () => {
  return getStateWithSelector('operationsReducer', 'loading')
}

export const getOperationsError = () => {
  return getStateWithSelector('operationsReducer', 'error')
}

export const getOperationsDashboardData = () => {
  return getStateWithSelector('dashboardOperationsReducer', 'data')
}

/*
 *
 * Selectors from slices
 *
 */

// ===========================================================================

/*
 * Audit
 */

// Cognito

export const getUserCognitoLogs = () => {
  return getStateWithSelector('auditCognitoReducer', 'logs')
}

export const getUserCognitoLogsLoading = () => {
  return getStateWithSelector('auditCognitoReducer', 'loading')
}

// Logs

export const getAuditLogs = () => {
  return getStateWithSelector('auditLogReducer', 'logs')
}

export const getAuditLogsCount = () => {
  return getStateWithSelector('auditLogReducer', 'count')
}

export const getAuditLogNextToken = () => {
  return getStateWithSelector('auditLogReducer', 'nextToken')
}

export const getAuditLogNextTokenCount = () => {
  return getStateWithSelector('auditLogReducer', 'nextTokenCount')
}

export const getAuditLogsLoading = () => {
  return getStateWithSelector('auditLogReducer', 'loading')
}

// ===========================================================================

/*
 * Calibration
 */

// Device

export const getCalibrationLoading = () => {
  return getStateWithSelector('calibrationDeviceReducer', 'loading')
}

export const getCalibrationHistory = () => {
  return getStateWithSelector('calibrationDeviceReducer', 'history')
}

export const getCalibrationInFlight = () => {
  return getStateWithSelector('calibrationDeviceReducer', 'calibrationInFlight')
}

// ===========================================================================

/*
 * Control
 */

// Control Device Config

export const getControlDeviceConfigs = () => {
  return getStateWithSelector('ccControlDeviceConfigReducer', 'configs')
}

export const getControlDeviceNewConfigs = () => {
  return getStateWithSelector('ccControlDeviceConfigReducer', 'newConfigs')
}

export const getControlDeviceLoading = () => {
  return getStateWithSelector('ccControlDeviceConfigReducer', 'loading')
}

export const getControlDeviceSaving = () => {
  return getStateWithSelector('ccControlDeviceConfigReducer', 'saving')
}

export const getControlDeviceError = () => {
  return getStateWithSelector('ccControlDeviceConfigReducer', 'error')
}

// ===========================================================================

/*
 * Fertigation
 */

// Fertilizer

export const getAllFertilizers = () => {
  return getStateWithSelector('fertilizerReducer', 'allFertilizers')
}

export const getFertilizerError = () => {
  return getStateWithSelector('fertilizerReducer', 'error')
}

export const getFertilizerLoading = () => {
  return getStateWithSelector('fertilizerReducer', 'loading')
}

// ===========================================================================

/*
 * Device Management
 */

// Init

export const getDeviceManagementInitLoading = () => {
  return getStateWithSelector('deviceManagementInitReducer', 'loading')
}

// Device Types

export const getDeviceTypes = () => {
  return getStateWithSelector(
    'deviceManagementDeviceTypeReducer',
    'deviceTypes'
  )
}

export const getDeviceFirmwareVersions = () => {
  return getStateWithSelector(
    'deviceManagementDeviceTypeReducer',
    'firmwareVersions'
  )
}

export const getDeviceTypeError = () => {
  return getStateWithSelector('deviceManagementDeviceTypeReducer', 'error')
}

export const getDeviceTypeUpdated = () => {
  return getStateWithSelector(
    'deviceManagementDeviceTypeReducer',
    'updatedDeviceType'
  )
}

export const getDeviceTypeTypes = () => {
  return getStateWithSelector(
    'deviceManagementDeviceTypeReducer',
    'deviceTypeTypes'
  )
}

export const getDeviceTypeIsSaving = () => {
  return getStateWithSelector('deviceManagementDeviceTypeReducer', 'isSaving')
}

// Flash Script

export const getFlashScripts = () => {
  return getStateWithSelector(
    'deviceManagementFlashScriptReducer',
    'flashScripts'
  )
}

export const getFlashScriptUpdated = () => {
  return getStateWithSelector(
    'deviceManagementFlashScriptReducer',
    'updatedFlashScript'
  )
}

export const getFlashScriptUploadData = () => {
  return getStateWithSelector(
    'deviceManagementFlashScriptReducer',
    'uploadData'
  )
}

// Measurements

export const getMeasurements = () => {
  return getStateWithSelector(
    'deviceManagementMeasurementReducer',
    'measurements'
  )
}

export const getNewMeasurement = () => {
  return getStateWithSelector(
    'deviceManagementMeasurementReducer',
    'newMeasurement'
  )
}

export const getNewMeasurementError = () => {
  return getStateWithSelector(
    'deviceManagementMeasurementReducer',
    'newMeasurementError'
  )
}

export const getSelectedMeasurement = () => {
  return getStateWithSelector(
    'deviceManagementMeasurementReducer',
    'selectedMeasurement'
  )
}

export const getUpdatedMeasurement = () => {
  return getStateWithSelector(
    'deviceManagementMeasurementReducer',
    'updatedMeasurement'
  )
}

export const getUpdateMeasurementError = () => {
  return getStateWithSelector(
    'deviceManagementMeasurementReducer',
    'updateMeasurementError'
  )
}

export const getMeasurementIsSaving = () => {
  return getStateWithSelector('deviceManagementMeasurementReducer', 'isSaving')
}

// ===========================================================================

/*
 * Diagnostics
 */

// Device is offline

export const getOfflineDevices = () => {
  return getStateWithSelector('diagnosticsDeviceIsOfflineReducer', 'devices')
}

export const getOfflineDevicesLoading = () => {
  return getStateWithSelector('diagnosticsDeviceIsOfflineReducer', 'loading')
}

// SensorSim

export const getSensorSimDiagnosticPings = () => {
  return getStateWithSelector('diagnosticsSensorSimReducer', 'requestedPings')
}

export const getSensorSimDetails = () => {
  return getStateWithSelector('diagnosticsSensorSimReducer', 'sensorSimDetails')
}
export const getSensorSimDiagnosticLoading = () => {
  return getStateWithSelector('diagnosticsSensorSimReducer', 'loading')
}
export const getSensorSimDiagnosticError = () => {
  return getStateWithSelector('diagnosticsSensorSimReducer', 'error')
}

// ===========================================================================

/*
 * Location
 */

export const getLocation = () => {
  return getStateWithSelector('locationReducer', 'location')
}

export const getFetchingLocation = () => {
  return getStateWithSelector('locationReducer', 'fetchingLocation')
}

// ===========================================================================

/*
 * Management
 */

// Dashboard

export const getDashboards = () => {
  return getStateWithSelector('managementDashboardReducer', 'dashboards')
}

export const getDashboardResetting = () => {
  return getStateWithSelector('managementDashboardReducer', 'resetting')
}

export const getDashboardReInit = () => {
  return getStateWithSelector('managementDashboardReducer', 'reInit')
}

export const getUseSimplifiedDashboard = () => {
  return getStateWithSelector(
    'managementDashboardReducer',
    'useSimplifiedDefault'
  )
}

export const getUseSimplifiedDashboardByLocation = () => {
  return getStateWithSelector(
    'managementDashboardReducer',
    'useSimplifiedByLocation'
  )
}

export const getDashboardDefaultSettingUpdated = () => {
  return getStateWithSelector(
    'managementDashboardReducer',
    'dashboardDefaultSettingUpdated'
  )
}

/*
 * Device
 */

export const getZoneDevices = () => {
  return getStateWithSelector('managementDeviceReducer', 'devices')
}

export const getZoneDevicesError = () => {
  return getStateWithSelector('managementDeviceReducer', 'error')
}

export const getDeviceLoading = () => {
  return getStateWithSelector('managementDeviceReducer', 'loading')
}

export const getDevice = () => {
  return getStateWithSelector('managementDeviceReducer', 'device')
}

export const getSupplierDevice = () => {
  return getStateWithSelector('managementDeviceReducer', 'supplierDevice')
}

/*
 * Feature flags
 */

export const getFeatureFlags = () => {
  return getStateWithSelector('managementFeatureFlagReducer', 'featureFlags')
}

export const getFeatureFlagsByObject = () => {
  return getStateWithSelector('managementFeatureFlagReducer', 'attachments')
}

export const getFeatureFlagsIsFetching = () => {
  return getStateWithSelector('managementFeatureFlagReducer', 'isFetching')
}

export const getFeatureFlagsIsSaving = () => {
  return getStateWithSelector('managementFeatureFlagReducer', 'isSaving')
}

/*
 * Hierarchy
 */

export const getZoneHierarchy = () => {
  return getStateWithSelector('managementHierarchyReducer', 'zoneHierarchy')
}

export const getZonesHierarchy = () => {
  return getStateWithSelector('managementHierarchyReducer', 'zonesHierarchy')
}

export const getZonesHierarchyIds = () => {
  return getStateWithSelector('managementHierarchyReducer', 'zonesHierarchyIds')
}

export const getSitesHierarchyIds = () => {
  return getStateWithSelector('managementHierarchyReducer', 'siteIds')
}

export const getZonesHierarchyFetched = () => {
  return getStateWithSelector(
    'managementHierarchyReducer',
    'zonesHierarchyFetched'
  )
}

export const getZonesHierarchyLoading = () => {
  return getStateWithSelector(
    'managementHierarchyReducer',
    'loadingZonesHierarchy'
  )
}

export const getZonesHierarchyDevicesLoading = () => {
  return getStateWithSelector(
    'managementHierarchyReducer',
    'loadingZonesHierarchyDevices'
  )
}

export const getZoneHierarchyLoading = () => {
  return getStateWithSelector(
    'managementHierarchyReducer',
    'loadingZoneHierarchy'
  )
}

// Organizations

export const getOrganizations = () => {
  return getStateWithSelector('managementOrganizationReducer', 'organizations')
}

export const getOrganizationsLoading = () => {
  return getStateWithSelector('managementOrganizationReducer', 'loading')
}

export const getOrganization = () => {
  return getStateWithSelector(
    'managementOrganizationReducer',
    'selectedOrganization'
  )
}

export const getUpdatedOrganization = () => {
  return getStateWithSelector(
    'managementOrganizationReducer',
    'updatedOrganization'
  )
}

export const getOrganizationError = () => {
  return getStateWithSelector(
    'managementOrganizationReducer',
    'organizationError'
  )
}

// Organization Labels

export const getOrganizationLabels = () => {
  return getStateWithSelector(
    'managementOrganizationLabelsReducer',
    'organizationLabels'
  )
}

export const getOrganizationLabelsLoading = () => {
  return getStateWithSelector('managementOrganizationLabelsReducer', 'loading')
}

export const getOrganizationLabel = () => {
  return getStateWithSelector(
    'managementOrganizationLabelsReducer',
    'organizationLabel'
  )
}

export const getAllOrganizationLabels = () => {
  return getStateWithSelector(
    'managementOrganizationLabelsReducer',
    'allOrganizationLabels'
  )
}

// Permission

export const getPermissions = () => {
  return getStateWithSelector('managementPermissionReducer', 'permissions')
}

export const getPermissionsError = () => {
  return getStateWithSelector('managementPermissionReducer', 'permissionsError')
}

// Role

export const getRole = () => {
  return getStateWithSelector('managementRoleReducer', 'role')
}

export const getRoles = () => {
  return getStateWithSelector('managementRoleReducer', 'roles')
}

export const getRolePermissions = () => {
  return getStateWithSelector('managementRoleReducer', 'role')
}

export const getRolesError = () => {
  return getStateWithSelector('managementRoleReducer', 'error')
}

export const getCurrentPermission = () => {
  return getStateWithSelector('managementRoleReducer', 'rolePermission')
}

export const getRoleUsers = () => {
  return getStateWithSelector('managementRoleReducer', 'roleUsers')
}

export const getRoleUsersFetched = () => {
  return getStateWithSelector('managementRoleReducer', 'roleUsersFetched')
}

// Settings

export const getSettings = () => {
  return getStateWithSelector('managementSettingsReducer', 'settings')
}

export const getSettingsIsFetching = () => {
  return getStateWithSelector('managementSettingsReducer', 'isFetching')
}

export const getSettingsIsSaving = () => {
  return getStateWithSelector('managementSettingsReducer', 'isSaving')
}

// Supplier

export const getFactorySuppliers = () => {
  return getStateWithSelector('managementSupplierReducer', 'suppliers')
}

export const getFactorySupplier = () => {
  return getStateWithSelector('managementSupplierReducer', 'supplier')
}

export const getFactorySupplierUsers = () => {
  return getStateWithSelector('managementSupplierReducer', 'users')
}

export const getFactorySupplierUser = () => {
  return getStateWithSelector('managementSupplierReducer', 'user')
}

export const getFactorySupplierUserDeleted = () => {
  return getStateWithSelector('managementSupplierReducer', 'userDeleted')
}

export const getFactorySuppliersLoading = () => {
  return getStateWithSelector('managementSupplierReducer', 'loading')
}

export const getFactorySuppliersSaving = () => {
  return getStateWithSelector('managementSupplierReducer', 'saving')
}

// User

export const getUserSliceError = () => {
  return getStateWithSelector('managementUserReducer', 'error')
}

export const getUsersLoading = () => {
  return getStateWithSelector('managementUserReducer', 'loading')
}

export const getCurrentUser = () => {
  return getStateWithSelector('managementUserReducer', 'currentUser')
}

export const getCurrentUserLoading = () => {
  return getStateWithSelector('managementUserReducer', 'currentUserLoading')
}

export const getCurrentUserOrganizations = () => {
  const currentUser = getStateWithSelector(
    'managementUserReducer',
    'currentUser'
  )
  return currentUser?.organizations ?? []
}

export const getUsers = () => {
  return getStateWithSelector('managementUserReducer', 'users')
}

export const getAllUsers = () => {
  return getStateWithSelector('managementUserReducer', 'allUsers')
}

export const getUser = () => {
  return getStateWithSelector('managementUserReducer', 'user')
}

export const getUpdatedUser = () => {
  return getStateWithSelector('managementUserReducer', 'updatedUser')
}

export const getDeletedUser = () => {
  return getStateWithSelector('managementUserReducer', 'deletedUser')
}

export const getResendUser = () => {
  return getStateWithSelector('managementUserReducer', 'resendUser')
}

// Zone

export const getWhatsAppLink = () => {
  return getStateWithSelector('managementWhatAppReducer', 'link')
}

export const getWhatsAppError = () => {
  return getStateWithSelector('managementWhatAppReducer', 'error')
}

export const getWhatsAppIsSaving = () => {
  return getStateWithSelector('managementWhatAppReducer', 'isSaving')
}

export const getWhatsAppIsAdding = () => {
  return getStateWithSelector('managementWhatAppReducer', 'isAdding')
}

export const getWhatsAppIsRemoving = () => {
  return getStateWithSelector('managementWhatAppReducer', 'isRemoving')
}

// Zone

export const getRootZone = () => {
  return getStateWithSelector('managementZoneReducer', 'rootZone')
}

export const getRootZoneLoading = () => {
  return getStateWithSelector('managementZoneReducer', 'rootZoneLoading')
}

export const getRootZoneRequested = () => {
  return getStateWithSelector('managementZoneReducer', 'rootZoneRequested')
}

export const getZoneUsers = () => {
  return getStateWithSelector('managementZoneReducer', 'users')
}

export const getZoneIsLoading = () => {
  return getStateWithSelector('managementZoneReducer', 'loading')
}

export const getCameras = () => {
  return getStateWithSelector('managementZoneReducer', 'cameras')
}

export const getInnerZones = () => {
  return getStateWithSelector('managementZoneReducer', 'innerZones')
}

export const getZoneLiveData = () => {
  return getStateWithSelector('managementZoneReducer', 'liveData')
}

export const getZoneSensors = () => {
  return getStateWithSelector('managementZoneReducer', 'sensors')
}

export const getZonesList = () => {
  return getStateWithSelector('managementZoneReducer', 'zonesList')
}

export const getZonesListLoading = () => {
  return getStateWithSelector('managementZoneReducer', 'zonesListLoading')
}

export const getZoneTree = () => {
  return getStateWithSelector('managementZoneReducer', 'zoneTree')
}

export const getZoneTreeLoading = () => {
  return getStateWithSelector('managementZoneReducer', 'zoneTreeLoading')
}

export const getRootZones = () => {
  return getStateWithSelector('managementZoneReducer', 'rootZones')
}

// TODO: Check this selector it doesn't seem right
export const getZones = (parent = 'root') => {
  return getStateWithSelector('managementZoneReducer', parent || 'root')
}

export const getZone = () => {
  return getStateWithSelector('managementZoneReducer', 'zone')
}

export const getZoneError = () => {
  return getStateWithSelector('managementZoneReducer', 'error')
}

export const getZoneLoading = () => {
  return getStateWithSelector('managementZoneReducer', 'zoneLoading')
}

export const getTargetZone = () => {
  return getStateWithSelector('managementZoneReducer', 'targetZone')
}

export const getTargetZoneLoading = () => {
  return getStateWithSelector('managementZoneReducer', 'targetZoneLoading')
}

// ===========================================================================

/*
 * FYI Tooltips
 */

export const getHelpTextsByLang = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'tooltipsHelpText')
}

export const getFyiTooltips = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'tooltips')
}

export const getFyiTooltipsRequested = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'tooltipsRequested')
}

export const getGeneratedHelpText = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'generatedText')
}

export const getLoadingGeneratedText = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'loadingGeneratedText')
}

export const getHelpTextUpserted = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'tooltipUpserted')
}

export const getHelpTextTranslationRequested = () => {
  return getStateWithSelector('fyiHelpTextReducer', 'translationRequested')
}

export const getReleaseNotesSections = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'sections')
}

export const getReleaseNotesSectionCreated = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'sectionCreated')
}

export const getReleaseNoteUpserted = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'releaseNoteUpserted')
}

export const getReleaseNotes = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'releaseNotes')
}

export const getReleaseNotesInitFetched = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'initFetched')
}

export const getReleaseNotesByLang = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'releaseNotesByLang')
}

export const getReleaseNotesByLangFetched = () => {
  return getStateWithSelector(
    'fyiReleaseNotesReducer',
    'releaseNotesByLangFetched'
  )
}

export const getReleaseNotesLoading = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'loading')
}

export const getReleaseNoteTranslationsRequested = () => {
  return getStateWithSelector('fyiReleaseNotesReducer', 'translationsRequested')
}

export const getFyiTags = () => {
  return getStateWithSelector('fyiTagsReducer', 'tags')
}

export const getFyiTagsRequested = () => {
  return getStateWithSelector('fyiTagsReducer', 'tagsRequested')
}

export const getFyiTagUpserted = () => {
  return getStateWithSelector('fyiTagsReducer', 'tagUpserted')
}

export const getFyiTagsLoading = () => {
  return getStateWithSelector('fyiTagsReducer', 'loading')
}

// ===========================================================================

/*
 * Managers
 */

export const getManagers = () => {
  return getStateWithSelector('managerReducer', 'managers')
}

export const getManager = () => {
  return getStateWithSelector('managerReducer', 'manager')
}

export const getManagerError = () => {
  return getStateWithSelector('managerReducer', 'error')
}

export const getManagerLoading = () => {
  return getStateWithSelector('managerReducer', 'loading')
}

export const getManagerSaving = () => {
  return getStateWithSelector('managerReducer', 'saving')
}

// ===========================================================================

/*
 * ML
 */

// Prediction

export const getNextHourPrediction = measurementId => {
  const nextHour = getStateWithSelector('mlPredictionReducer', 'nextHour')

  return nextHour[measurementId]
}

// Site tracker

export const getSiteTrackerStatus = () => {
  return getStateWithSelector('mlSiteTrackerReducer', 'status')
}

// ===========================================================================

/*
 * Navigation
 */

export const getNavigationSelectedLevel = () => {
  return getStateWithSelector('navigationReducer', 'selectedLevel')
}

export const getNavigationLocationSelector = () => {
  return getStateWithSelector('navigationReducer', 'locationSelector')
}

export const getNavigationPermissions = () => {
  return getStateWithSelector('navigationReducer', 'permissions')
}

export const getNavigationOptionsRoute = () => {
  return getStateWithSelector('navigationReducer', 'optionsRoute')
}

export const getNavigationOptionsSensor = () => {
  return getStateWithSelector('navigationReducer', 'optionsSensor')
}

export const getNavigationPanelPrimaryOpen = () => {
  return getStateWithSelector('navigationReducer', 'panelPrimaryOpen')
}

export const getNavigationPanelPrimaryLock = () => {
  return getStateWithSelector('navigationReducer', 'panelPrimaryLock')
}

export const getNavigationPanelLocationSelectorOpen = () => {
  return getStateWithSelector('navigationReducer', 'panelLocationSelectorOpen')
}

// ===========================================================================

/*
 * OTA
 */

export const getOtaExecutionId = () => {
  return getStateWithSelector('otaReducer', 'executionId')
}

export const getOtaStarting = () => {
  return getStateWithSelector('otaReducer', 'starting')
}

export const getOtaError = () => {
  return getStateWithSelector('otaReducer', 'error')
}

// ===========================================================================

/*
 * Pinger
 */

export const getDeviceStatuses = () => {
  return getStateWithSelector('pingerReducer', 'devices')
}

export const getDeviceStatusesLoading = () => {
  return getStateWithSelector('pingerReducer', 'loading')
}

export const getDeviceStatusesError = () => {
  return getStateWithSelector('pingerReducer', 'error')
}

export const getDeviceStatusesSuccess = () => {
  return getStateWithSelector('pingerReducer', 'successMsg')
}

// ===========================================================================

/*
 * Reports
 */

export const getReports = () => {
  return getStateWithSelector('reportReducer', 'reports')
}

export const getReportsLoading = () => {
  return getStateWithSelector('reportReducer', 'loading')
}

// ===========================================================================

/*
 * Service Worker
 */

export const getSWInit = () => {
  return getStateWithSelector('serviceWorkerReducer', 'swInit')
}

export const getSWUpdated = () => {
  return getStateWithSelector('serviceWorkerReducer', 'swUpdated')
}

export const getSWRegistration = () => {
  return getStateWithSelector('serviceWorkerReducer', 'swRegistration')
}

// ===========================================================================

/*
 * Supplier
 */

// Enrollment

export const getDeviceVerified = () => {
  return getStateWithSelector('supplierEnrollmentReducer', 'verified')
}

export const getDeviceEnrolled = () => {
  return getStateWithSelector('supplierEnrollmentReducer', 'enrolled')
}

export const getDeviceFetched = () => {
  return getStateWithSelector('supplierEnrollmentReducer', 'fetched')
}

// ===========================================================================

/*
 * Support
 */

export const getSupportEmailSent = () => {
  return getStateWithSelector('supportReducer', 'emailSent')
}

export const getSupportEmailError = () => {
  return getStateWithSelector('supportReducer', 'error')
}

export const getSupportEmailSending = () => {
  return getStateWithSelector('supportReducer', 'sending')
}

// ===========================================================================

/*
 * Thresholds
 */

export const getThresholds = () => {
  return getStateWithSelector('thresholdReducer', 'thresholds')
}

export const getThreshold = () => {
  return getStateWithSelector('thresholdReducer', 'threshold')
}

export const getThresholdError = () => {
  return getStateWithSelector('thresholdReducer', 'error')
}

export const getThresholdLoading = () => {
  return getStateWithSelector('thresholdReducer', 'loading')
}

export const getThresholdSaving = () => {
  return getStateWithSelector('thresholdReducer', 'saving')
}

export const getThresholdSetting = () => {
  return getStateWithSelector('thresholdReducer', 'setting')
}

export const getThresholdCreated = () => {
  return getStateWithSelector('thresholdReducer', 'createdThreshold')
}

export const getThresholdUpdated = () => {
  return getStateWithSelector('thresholdReducer', 'updatedThreshold')
}

export const getThresholdDeleted = () => {
  return getStateWithSelector('thresholdReducer', 'deletedThreshold')
}

// Sensor Threshold User Acknowledgment

export const getSensorThresholdUserAcks = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdUserAckReducer',
    'notificationAcks'
  )
}

export const getSensorThresholdUserAckLoading = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdUserAckReducer',
    'isLoading'
  )
}

// Sensor Treshold

export const getSensorThresholdStatuses = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdStatusReducer',
    'sensorThresholdStatusesByRoot'
  )
}

export const getSensorThresholdStatusesLoading = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdStatusReducer',
    'isLoading'
  )
}

// Sensor Threshold User Snooze

export const getSensorThresholdUserSnoozes = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdUserSnooze',
    'notificationSnoozes'
  )
}

export const getSensorThresholdUserSnoozeErrors = () => {
  return getStateWithSelector('thresholdSensorThresholdUserSnooze', 'errors')
}

export const getSensorThresholdUserSnoozeCreateError = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdUserSnooze',
    'snoozeCreateError'
  )
}

export const getSensorThresholdUserSnoozeDeleteError = () => {
  return getStateWithSelector(
    'thresholdSensorThresholdUserSnooze',
    'snoozeDeleteError'
  )
}

export const getSensorThresholdUserSnoozeLoading = () => {
  return getStateWithSelector('thresholdSensorThresholdUserSnooze', 'isLoading')
}

// ===========================================================================

/*
 * Timestream
 */

// Ingestion Frequency

export const getSiteIngestionFrequency = () => {
  return getStateWithSelector('timestreamIngestionFrequencyReducer', 'config')
}

export const getSiteIngestionFrequencySaving = () => {
  return getStateWithSelector('timestreamIngestionFrequencyReducer', 'saving')
}

export const getSiteIngestionFrequencyUpdated = () => {
  return getStateWithSelector(
    'timestreamIngestionFrequencyReducer',
    'configUpdated'
  )
}

// Usage plan

export const getUsagePlanSliceError = () => {
  return getStateWithSelector('timestreamUsagePlanReducer', 'error')
}

export const getUsagePlanSliceLoading = () => {
  return getStateWithSelector('timestreamUsagePlanReducer', 'loading')
}

export const getUsagePlan = () => {
  return getStateWithSelector('timestreamUsagePlanReducer', 'usagePlan')
}

export const getApiKeys = () => {
  return getStateWithSelector('timestreamUsagePlanReducer', 'apiKeys')
}

export const getApiKey = () => {
  return getStateWithSelector('timestreamUsagePlanReducer', 'apiKey')
}

export const getApiKeyRegenerating = () => {
  return getStateWithSelector('timestreamUsagePlanReducer', 'regenerating')
}

// ===========================================================================

/*
 * Utils
 */

export const getIsLoading = () => {
  return getStateWithSelector('utilReducer', 'isLoading')
}

export const getIsLoadingSection = () => {
  return getStateWithSelector('utilReducer', 'isLoadingSection')
}

export const getLang = () => {
  return getStateWithSelector('utilReducer', 'lang')
}

export const getShowBanner = () => {
  return getStateWithSelector('utilReducer', 'banner')
}

export const getSensorError = () => {
  return getStateWithSelector('sensors', 'error')
}

// ===========================================================================

/*
 * Video
 */

// Stream

export const getVideoStreams = () => {
  return getStateWithSelector('videoStreamReducer', 'streams')
}

export const getVideoStreamUrl = () => {
  return getStateWithSelector('videoStreamReducer', 'url')
}

export const getVideoStreamError = () => {
  return getStateWithSelector('videoStreamReducer', 'error')
}

// ===========================================================================

/*
 * Weather
 */

// Forecast

export const getWeatherForecast = () => {
  return getStateWithSelector('weatherForecastReducer', 'forecast')
}

export const getWeatherForecastRequested = () => {
  return getStateWithSelector('weatherForecastReducer', 'forecastRequested')
}

export const getLoadingWeatherForecast = () => {
  return getStateWithSelector('weatherForecastReducer', 'isLoading')
}
