import { Suspense, lazy, useEffect } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { Loader, Flex, Text, Button } from './primitives'

import { Page } from './components/UIShell'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_REPORTS,
  ENABLE_STATUS_PAGE,
  ENABLE_MAPS,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

import {
  getNavigationLocationSelector,
  getCurrentUser
} from '@/reducers/selectors'

/* Routes */
import Root from './components/Root'
import PrivacyPolicy from './components/PrivacyPolicy'
import NotFoundPage from './components/NotFoundPage'
import ZonesPage from './components/ZonesPage'
import DashboardPageV2 from './components/DashboardPageV2'
import DataboardPageV2 from './components/DataboardPageV2'
import Map from './components/Map'
import AdminPage from './components/AdminPage'
import Enrollment from './components/Enrollment'
import Operations from './components/Operations'
import ReleaseNotes from './components/ReleaseNotes'
import StatusPage from './components/StatusPage'

/** Algolia */
import routeKeywords, { fallbackKeywords } from './algolia/keywords'
import { useArticleSuggestion } from './algolia/context'
import FindDashboard from './components/FindDashboard'
import TrialModeView from './components/TrialModeView'

const CalibrationPage = lazy(() => import('./components/Calibration'))
const Profile = lazy(() => import('./components/Profile'))
const ReportsPage = lazy(() => import('./components/Reports'))
const VideoFeedsPage = lazy(() => import('./components/VideoFeeds'))

export const routes = [
  { path: '/', exact: true, Component: Root, auth: false },
  {
    path: '/privacy-policy',
    exact: true,
    Component: PrivacyPolicy,
    auth: false
  },
  { path: '/profile', exact: true, Component: Profile },
  {
    path: '/operations',
    Component: Operations,
    addYPadding: false
  },
  {
    path: '/find-dashboard',
    exact: true,
    Component: FindDashboard,
    auth: false
  },
  { path: '/zones', Component: ZonesPage, exact: true },
  {
    path: '/zones/:zone+/video-feeds',
    Component: VideoFeedsPage,
    exact: true
  },
  {
    path: '/zones/:zone+/reports',
    Component: ReportsPage,
    exact: true,
    featureFlag: AVAILABLE_FEATURE_FLAGS[ENABLE_REPORTS]
  },
  {
    path: '/zones/:zone+/status/:page?',
    Component: StatusPage,
    exact: false,
    featureFlag: AVAILABLE_FEATURE_FLAGS[ENABLE_STATUS_PAGE]
  },
  {
    path: '/zones/:zone+/trial-mode/',
    Component: TrialModeView,
    exact: false
  },
  {
    path: '/zones/:zone+/sensor/:sensorType/:sensorId/calibration/:measurement?',
    Component: CalibrationPage,
    exact: false
  },
  {
    path: '/zones/:zone+/sensor/:sensorType/:sensorId/data',
    Component: DataboardPageV2,
    exact: true
  },
  {
    path: '/zones/:zone+/data',
    Component: DataboardPageV2,
    exact: true
  },
  {
    path: '/zones/:zone+/sensor/:sensorType/:sensorId',
    Component: DashboardPageV2,
    exact: true
  },
  {
    path: '/zones/:zone+/map',
    Component: Map,
    exact: true,
    addXPadding: false,
    addYPadding: false,
    featureFlag: AVAILABLE_FEATURE_FLAGS[ENABLE_MAPS]
  },
  {
    path: '/zones/:zone+',
    Component: DashboardPageV2,
    exact: true,
    isFullSizePage: true
  },
  { path: '/admin', Component: AdminPage },
  { path: '/enroll-device/:deviceId', Component: Enrollment },
  { path: '/release-notes', Component: ReleaseNotes },
  { path: '*', Component: NotFoundPage }
]

const LoaderPage = () => {
  return (
    <Page>
      <Loader isLoading={true} style={{ position: 'static' }} />
    </Page>
  )
}

const RouteWithProps = ({
  path = null,
  exact = false,
  Component = null,
  limitWidth = false,
  addXPadding = true,
  addYPadding = true,
  isFullSizePage = false,
  componentProps = {}
}) => {
  const fullSizePageStyle = isFullSizePage
    ? {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        flexGrow: 1
      }
    : null

  const { setKeywords } = useArticleSuggestion()

  useEffect(() => {
    const keywords = routeKeywords[path] || fallbackKeywords
    setKeywords(keywords)
  }, [path])

  return (
    <Route key={path} exact={exact} path={path}>
      <Page
        limitWidth={limitWidth}
        addXPadding={addXPadding}
        addYPadding={addYPadding}
        style={fullSizePageStyle}
      >
        <Suspense fallback={<LoaderPage />}>
          <Component {...componentProps} />
        </Suspense>
      </Page>
    </Route>
  )
}

function AccessDenied() {
  const history = useHistory()

  function redirect() {
    history.push('/zones')
  }

  return (
    <Flex direction='column' alignCrossAxis='flex-start' axisGap={400}>
      <Text>{I18n.get('You do not have permission to view this page.')}</Text>
      <Button onClick={redirect} size='small' variant='primary'>
        {I18n.get('Go back')}
      </Button>
    </Flex>
  )
}

const Routes = () => {
  const location = useLocation()

  const currentUser = getCurrentUser()
  const locationSelector = getNavigationLocationSelector()
  const organization = locationSelector.get('organization')

  const restrictedRoutes = routes?.map(route => {
    if (route?.featureFlag) {
      if (!organization) return { ...route, Component: LoaderPage }

      const isEnabled = hasFeatureFlagEnabled(
        currentUser,
        route?.featureFlag,
        organization?.id
      )

      const restrictedRoute = {
        ...route,
        Component: AccessDenied,
        addXPadding: true,
        addYPadding: true
      }

      return isEnabled ? route : restrictedRoute
    }

    return route
  })

  return (
    <Switch location={location}>
      {restrictedRoutes.map(props => (
        <RouteWithProps key={props.path} {...props} />
      ))}
    </Switch>
  )
}

export default Routes
