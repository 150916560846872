import { useEffect, useState } from 'react'
import {
  requiredVectorMeasurements,
  conversionVectorFunctions
} from '@/Util/MeasurementUtils'

const getDateString = timestamp => {
  return new Date(timestamp).toISOString().split('T')[0]
}

const processDliData = parEntries => {
  return parEntries.reduce(
    (acc, item, index) => {
      const date = getDateString(item.x)

      const dliValue =
        item?.y != null
          ? conversionVectorFunctions.daily_light_integral({
              par: item.y,
              interval: 'hourly',
              useNew: true
            })
          : null

      if (dliValue === null) {
        return acc
      }

      let newItem = { ...item, y: dliValue }

      if (acc.date === date && acc.calculatedData.length > 0) {
        newItem.y =
          newItem.y + acc.calculatedData[acc.calculatedData.length - 1].y
      }

      acc.calculatedData.push(newItem)
      acc.date = date

      return acc
    },
    { calculatedData: [], date: null }
  ).calculatedData
}

const computeVectorValue = (metric, sectionData, timestamp) => {
  if (!requiredVectorMeasurements[metric]) {
    return null
  }

  if (metric === 'daily_light_integral') {
    const parEntries = Object.values(sectionData?.['par']?.dataset || {})
      .filter(entry => getDateString(entry.x) === getDateString(timestamp))
      .sort((a, b) => new Date(a.x) - new Date(b.x))

    if (parEntries.length === 0) return null

    const processedData = processDliData(parEntries)
    const matchingEntry = processedData.find(entry => entry.x === timestamp)
    return matchingEntry?.y ?? null
  }

  const requiredMeasurements = requiredVectorMeasurements[metric]
  const params = {}
  for (const measurement of requiredMeasurements) {
    const entry = Object.values(sectionData?.[measurement]?.dataset || {}).find(
      entry => entry.x === timestamp
    )
    if (!entry) {
      return null
    }
    params[measurement] = entry.y
  }

  const conversionFn = conversionVectorFunctions[metric]
  if (!conversionFn) {
    return null
  }

  return conversionFn(params)
}

export const useDashboardMetrics = (
  dashboardData,
  sectionAId,
  sectionBId,
  metrics
) => {
  const [transformedData, setTransformedData] = useState([])

  useEffect(() => {
    if (!dashboardData || !sectionAId || !sectionBId) return

    const sectionA = dashboardData[sectionAId]
    const sectionB = dashboardData[sectionBId]

    const timestampSet = new Set()
    metrics.forEach(metric => {
      const metricId = metric.id

      if (requiredVectorMeasurements[metricId]) {
        const requiredMeasurements = requiredVectorMeasurements[metricId]
        requiredMeasurements.forEach(measurement => {
          Object.values(sectionA?.[measurement]?.dataset || {}).forEach(
            entry => {
              timestampSet.add(entry.x)
            }
          )
          Object.values(sectionB?.[measurement]?.dataset || {}).forEach(
            entry => {
              timestampSet.add(entry.x)
            }
          )
        })
      } else {
        Object.values(sectionA?.[metricId]?.dataset || {}).forEach(entry => {
          timestampSet.add(entry.x)
        })
        Object.values(sectionB?.[metricId]?.dataset || {}).forEach(entry => {
          timestampSet.add(entry.x)
        })
      }
    })

    const sortedTimestamps = Array.from(timestampSet).sort()

    const transformed = sortedTimestamps.map(timestamp => {
      const data = { timestamp }

      metrics.forEach(metric => {
        const metricId = metric.id

        if (requiredVectorMeasurements[metricId]) {
          const sectionAValue = computeVectorValue(
            metricId,
            sectionA,
            timestamp
          )
          const sectionBValue = computeVectorValue(
            metricId,
            sectionB,
            timestamp
          )

          data[`sectionA_${metricId}`] = sectionAValue ?? 0
          data[`sectionB_${metricId}`] = sectionBValue ?? 0
        } else {
          const sectionAEntry = Object.values(
            sectionA?.[metricId]?.dataset || {}
          ).find(entry => entry.x === timestamp)
          const sectionBEntry = Object.values(
            sectionB?.[metricId]?.dataset || {}
          ).find(entry => entry.x === timestamp)

          data[`sectionA_${metricId}`] = sectionAEntry?.y || 0
          data[`sectionB_${metricId}`] = sectionBEntry?.y || 0
        }
      })

      return data
    })

    setTransformedData(transformed)
  }, [dashboardData, sectionAId, sectionBId, metrics])

  return transformedData
}
