import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import ReactSelect from 'react-select'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'

import { fetchProduceThresholds } from '@/slices/operations/produceThreshold'

import { createCrop, requestCrop, updateCrop } from '@/actions/operations/crop'

import {
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsProduceThresholds,
  getOperationsCrop,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import OrganizationInput from '../../../Shared/Organization/Input'
import ZoneNavigator from '../../../Shared/ZoneNavigator'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../../history'
import Strings from '../Strings'

import {
  initialState,
  reducer,
  UPDATE_INPUT,
  SET_STATE,
  UPDATE_SITE_ID
} from './state'

import {
  CROP_STATUSES,
  CROP_GROWTH_MEDIUMS,
  CROP_HYDRATION_METHODS,
  CROP_ROOF_COVERS,
  CROP_SECONDSKY_VARIANTS,
  CROP_BLOCKING_RATIOS,
  CROP_NET_TYPES
} from '../utils'
import { FIELDS, SCHEMA } from './config'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function CropForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const produceThresholds = getOperationsProduceThresholds()

  const crop = getOperationsCrop()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const statuses = CROP_STATUSES(strings)
  const growthMediums = CROP_GROWTH_MEDIUMS(strings)
  const hydrationMethods = CROP_HYDRATION_METHODS(strings)
  const roofCovers = CROP_ROOF_COVERS(strings)
  const secondskyVariants = CROP_SECONDSKY_VARIANTS(strings)
  const blockingRatios = CROP_BLOCKING_RATIOS(strings)
  const netTypes = CROP_NET_TYPES(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(fetchProduceThresholds())
  }, [dispatch])

  useEffect(() => {
    if (itemId && crop.id !== itemId) {
      dispatch(requestCrop({ cropId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (crop?.id && crop.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...crop }
      })
    }
  }, [crop])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.sites.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'siteId',
        value: state.sites[0]
      })
    }
  }, [state.sites])

  useEffect(() => {
    if (!itemId && state.produces.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'produceId',
        value: state.produces[0]
      })
    }
  }, [state.produces])

  useEffect(() => {
    if (!itemId && state.varieties.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'varietyId',
        value: state.varieties[0]
      })
    }
  }, [state.varieties])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA(
        formState.roofCover,
        formState.secondskyVariant
      ).validate(formState, { abortEarly: false })
      setWaiting(true)

      if (payload.produceThresholdId === '') payload.produceThresholdId = null

      if (itemId) {
        payload.cropId = itemId
        dispatch(updateCrop(payload))
      } else {
        dispatch(createCrop(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleProduceInput = e => {
    const produceId = e.currentTarget.value
    let produceThresholdId = formState.produceThresholdId
    if (produceId) {
      const produceIndex = produces.findIndex(p => p.id === produceId)
      if (produceIndex >= 0) {
        if (produces[produceIndex].produceThresholdId) {
          produceThresholdId = produces[produceIndex].produceThresholdId
        }
      }
    }
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        produceId: produceId,
        produceThresholdId: produceThresholdId,
        varietyId: ''
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        weight: '',
        packageUnits: [],
        produceId: '',
        varietyId: ''
      }
    })
  }

  const onSelectZone = ({ id, parentId }) => {
    if (parentId) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'zoneId',
        value: id
      })
    } else {
      dispatchFormState({
        type: UPDATE_SITE_ID,
        siteId: id
      })
    }
  }

  const handleGrowthMediumInput = options => {
    const newValues = options.map(o => o.value)
    dispatchFormState({
      type: UPDATE_INPUT,
      name: 'growthMedium',
      value: newValues
    })
  }

  const getGrowthMediumValue = () => {
    if (formState.growthMedium === null) {
      return []
    }
    return Object.entries(growthMediums)
      .filter(e => formState.growthMedium.indexOf(e[0]) >= 0)
      .map(e => ({
        value: e[0],
        label: e[1]
      }))
  }

  const filterGrowthMedium = () => {
    return Object.entries(growthMediums).map(e => ({
      value: e[0],
      label: e[1]
    }))
  }

  const filterProduces = () => {
    return produces.filter(p => p.organizationId === formState.organizationId)
  }

  const filterVarieties = () => {
    return varieties.filter(v => v.produce.id === formState.produceId)
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveCrop}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box className='Crop'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.crop}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                className='Operations__Form__Fields'
                direction='column'
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.location}
                  </Text>
                  <ZoneNavigator
                    organizationId={formState.organizationId}
                    siteId={formState.siteId}
                    zoneId={formState.zoneId}
                    onSelectZone={onSelectZone}
                  />
                  <InputError error={errors?.zoneId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flexGrow: 0 }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.status}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.status}
                      value={formState.status}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {Object.entries(statuses).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.status} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.produce}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.produce}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.produceId}
                    value={formState.produceId}
                    onChange={handleProduceInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterProduces().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.produceId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.variety}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.varietyId}
                    value={formState.varietyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterVarieties().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.varietyId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flexGrow: 0 }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.produceThreshold}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.produceThresholdId}
                      value={formState.produceThresholdId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {produceThresholds?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.produceThresholdId} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box className='Crop'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.conditions}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.plantingArea}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.plantingArea}
                        value={formState.plantingArea}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>
                        {strings.metersSquared}
                      </span>
                    </Box>
                    <InputError error={errors?.plantingArea} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.seedQuantity}
                    </Text>
                    <Input
                      className='Operations__Input'
                      type='number'
                      name={FIELDS.seedQuantity}
                      value={formState.seedQuantity}
                      onChange={handleNumericInput}
                    />
                    <InputError error={errors?.seedQuantity} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.hydrationMethod}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.hydrationMethod}
                      value={formState.hydrationMethod}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {Object.entries(hydrationMethods).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.hydrationMethod} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.growthMedium}
                    </Text>
                    <ReactSelect
                      className='Operations__Form__Select'
                      classNamePrefix={'ReactSelect ReactSelect__Multi'}
                      name={FIELDS.growthMedium}
                      value={getGrowthMediumValue()}
                      onChange={handleGrowthMediumInput}
                      isMulti={true}
                      menuPlacement={'top'}
                      options={filterGrowthMedium()}
                    />
                    <InputError error={errors?.growthMedium} />
                  </Flex>
                </Label>
              </Flex>
            </Box>

            <Box className='Crop'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.secondsky}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.roofCover}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.roofCover}
                      value={formState.roofCover}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {Object.entries(roofCovers).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.roofCover} />
                  </Flex>
                </Label>
                {formState.roofCover === 'secondsky' && (
                  <Label style={{ flex: 1 }}>
                    <Flex axisGap={300} direction='column'>
                      <Text variant='page' tone={700}>
                        {strings.blockingRatio}
                      </Text>
                      <Select
                        className='Operations__Select'
                        name={FIELDS.blockingRatio}
                        value={formState.blockingRatio}
                        onChange={handleInput}
                      >
                        <option default value=''>
                          {strings.notApplicable}
                        </option>
                        {Object.entries(blockingRatios).map(([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                      <InputError error={errors?.blockingRatio} />
                    </Flex>
                  </Label>
                )}
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                {formState.roofCover === 'secondsky' && (
                  <Label style={{ flex: 1 }}>
                    <Flex axisGap={300} direction='column'>
                      <Text variant='page' tone={700}>
                        {strings.secondskyVariant}
                      </Text>
                      <Select
                        className='Operations__Select'
                        name={FIELDS.secondskyVariant}
                        value={formState.secondskyVariant}
                        onChange={handleInput}
                      >
                        <option default value=''>
                          {strings.selectDefault}
                        </option>
                        {Object.entries(secondskyVariants).map(([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                      <InputError error={errors?.secondskyVariant} />
                    </Flex>
                  </Label>
                )}
                {formState.secondskyVariant === 'net' && (
                  <Label style={{ flex: 1 }}>
                    <Flex axisGap={300} direction='column'>
                      <Text variant='page' tone={700}>
                        {strings.netType}
                      </Text>
                      <Select
                        className='Operations__Select'
                        name={FIELDS.netType}
                        value={formState.netType}
                        onChange={handleInput}
                      >
                        <option default value=''>
                          {strings.selectDefault}
                        </option>
                        {Object.entries(netTypes).map(([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                      <InputError error={errors?.netType} />
                    </Flex>
                  </Label>
                )}
              </Flex>
              <Flex direction='column' style={{ flex: 1 }}>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.secondskyInformation}
                  </Text>
                </Label>
                <Input
                  as='textarea'
                  value={formState.secondskyInformation}
                  name={FIELDS.secondskyInformation}
                  onChange={handleInput}
                />
                <InputError error={errors?.secondskyInformation} />
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.schedule}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.estimatedSowDate}
                    </Text>
                    <DatePicker
                      minDate={new Date()}
                      selected={formState.estimatedSowDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.estimatedSowDate)
                      }
                    />
                    <InputError error={errors?.estimatedSowDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.sowDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.sowDate}
                      className='Operations__Form__Select'
                      onChange={date => handleDateInput(date, FIELDS.sowDate)}
                    />
                    <InputError error={errors?.sowDate} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.estimatedTransplantDate}
                    </Text>
                    <DatePicker
                      minDate={new Date()}
                      selected={formState.estimatedTransplantDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.estimatedTransplantDate)
                      }
                    />
                    <InputError error={errors?.estimatedTransplantDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.transplantDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.transplantDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.transplantDate)
                      }
                    />
                    <InputError error={errors?.transplantDate} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.estimatedHarvestStartDate}
                    </Text>
                    <DatePicker
                      minDate={new Date()}
                      selected={formState.estimatedHarvestStartDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.estimatedHarvestStartDate)
                      }
                    />
                    <InputError error={errors?.estimatedHarvestStartDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.harvestStartDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.harvestStartDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.harvestStartDate)
                      }
                    />
                    <InputError error={errors?.harvestStartDate} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.estimatedHarvestEndDate}
                    </Text>
                    <DatePicker
                      minDate={new Date()}
                      selected={formState.estimatedHarvestEndDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.estimatedHarvestEndDate)
                      }
                    />
                    <InputError error={errors?.estimatedHarvestEndDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.harvestEndDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.harvestEndDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.harvestEndDate)
                      }
                    />
                    <InputError error={errors?.harvestEndDate} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default CropForm
