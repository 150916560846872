import FlexV2 from '@/primitives/FlexV2'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import Button from '@/primitives/Button'
import strings from '../../Strings'
import SensorFilterDialog from './SensorFilterDialog'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEnvSensors, setSoilSensors } from '@/slices/management/trial'
import { getTrialState } from '../../utils'
import DeviceFilterButton from './DeviceFilterButton'

const MetricsHeader = ({
  type,
  viewMode,
  showDelta,
  onDeltaToggle,
  onViewModeChange,
  sectionAId,
  sectionBId
}) => {
  const dispatch = useDispatch()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { envSensorsA, envSensorsB, soilSensorsA, soilSensorsB } =
    useSelector(getTrialState)

  const hasActiveFilters =
    type === 'envirosense'
      ? envSensorsA.length > 0 || envSensorsB.length > 0
      : soilSensorsA.length > 0 || soilSensorsB.length > 0

  const handleClearFilters = () => {
    if (type === 'envirosense') {
      dispatch(
        setEnvSensors({
          sectionA: [],
          sectionB: []
        })
      )
    } else {
      dispatch(
        setSoilSensors({
          sectionA: [],
          sectionB: []
        })
      )
    }
  }

  const title = `${
    strings[type === 'envirosense' ? 'environmental' : 'soil']
  } ${strings.metrics}`

  return (
    <>
      <FlexV2 alignMainAxis='space-between' alignCrossAxis='center'>
        <FlexV2 alignCrossAxis='center' axisGap={200}>
          <Icon
            name={type === 'envirosense' ? 'thermostat' : 'water_drop'}
            size={400}
            variant='primary'
            tone={600}
          />
          <Text size={400} fontWeight={600}>
            {title}
          </Text>
        </FlexV2>
        <FlexV2 alignCrossAxis='center' axisGap={400}>
          {viewMode === 'historical' && (
            <>
              <DeviceFilterButton
                hasActiveFilters={hasActiveFilters}
                onClick={() => setIsDialogOpen(true)}
                onClearFilters={handleClearFilters}
                sensorType={type}
              />
              <Button
                variant='outline'
                iconBefore='compare_arrows'
                onClick={onDeltaToggle}
              >
                {showDelta ? strings.showSections : strings.showDelta}
              </Button>
            </>
          )}
          <FlexV2>
            <Button
              variant={viewMode === 'live' ? 'primary' : 'outline'}
              onClick={() => onViewModeChange('live')}
            >
              {strings.liveData}
            </Button>
            <Button
              variant={viewMode === 'historical' ? 'primary' : 'outline'}
              onClick={() => onViewModeChange('historical')}
            >
              {strings.historical}
            </Button>
          </FlexV2>
        </FlexV2>
      </FlexV2>
      <SensorFilterDialog
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        sectionAId={sectionAId}
        sectionBId={sectionBId}
        dialogMode={type}
      />
    </>
  )
}

export default MetricsHeader
