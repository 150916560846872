import { I18n } from 'aws-amplify'

const Strings = (orgLabels) => ({

  /* LocationSelector */
  select_location: I18n.get('Select location'),
  select_option: I18n.get('Select option'),

  /* LocationSelector - Singular */
  organization: I18n.get('Organization'),
  site: orgLabels.site.text,
  facility: orgLabels.facility.text,
  room: orgLabels.room.text,
  zone: orgLabels.zone.text,
  subzone: orgLabels.subzone.text,
  device: I18n.get('Device'),
  sensor: I18n.get('Sensor'),

  /* LocationSelector - Plural */
  organizations: I18n.get('Organizations'),
  sites: orgLabels.site.textPlural,
  facilities: orgLabels.facility.textPlural,
  rooms: orgLabels.room.textPlural,
  zones: orgLabels.zone.textPlural,
  subzones: orgLabels.subzone.textPlural,
  devices: I18n.get('Devices'),
  sensors: I18n.get('Sensors'),

  /* Admin */
  users: I18n.get('Users'),
  roles: I18n.get('Roles'),
  user_management: I18n.get('User Management'),
  user_roles: I18n.get('User Roles'),
  measurements: I18n.get('Measurements'),
  flash_scripts: I18n.get('Flash Scripts'),
  types: I18n.get('Types'),
  models: I18n.get('Models'),
  vendors: I18n.get('Vendors'),
  device_management: I18n.get('Device Management'),
  device_types: I18n.get('Device Types'),
  data_api: I18n.get('Data API'),
  audit: I18n.get('Audit Logs'),
  admin_suppliers: I18n.get('Suppliers'),
  recommendations: I18n.get('Recommendations'),
  produceThresholds: I18n.get('Produce Thresholds'),
  produceThresholdMessages: I18n.get('Threshold Messages'),

  /* General */
  admin: I18n.get('Admin'),
  back_to_menu: I18n.get('Back to menu'),
  update_location: I18n.get('Update Location'),
  about: I18n.get('About'),
  contact: I18n.get('Contact'),

  settings: I18n.get('Settings'),
  thresholds: I18n.get('Thresholds'),
  status: I18n.get('Status'),
  trial_mode_view: I18n.get('Trial Mode'),
  dashboard: I18n.get('Dashboard'),
  databoard: I18n.get('Databoard'),
  map: I18n.get('Map'),
  fleet_management: I18n.get('Fleet Management'),
  video_feeds: I18n.get('Video Feeds'),
  reports: I18n.get('Reports'),
  blueprint: I18n.get('Blueprint'),
  crops: I18n.get('Crops'),
  harvest: I18n.get('Harvest'),
  plans: I18n.get('Plans'),
  managers: I18n.get('Managers'),
  fertigation: I18n.get('Fertigation'),

  /* Organization */
  inventory: I18n.get('Inventory'),
  transfers: I18n.get('Transfers'),
  packaging: I18n.get('Packaging'),
  packaging_units: I18n.get('Packaging Units'),
  fertilizer: I18n.get('Fertilizer'),
  storage_facilities: I18n.get('Storage Facilities'),
  shipping_methods: I18n.get('Shipping Methods'),
  suppliers: I18n.get('Suppliers'),
  products: I18n.get('Products'),
  customers: I18n.get('Customers'),
  branches: I18n.get('Branches'),
  unit_prices: I18n.get('Unit Prices'),
  sales: I18n.get('Sales'),
  payment_methods: I18n.get('Payment Methods'),
  payment_terms: I18n.get('Payment Terms'),
  returns: I18n.get('Returns'),
  waste: I18n.get('Waste'),
  management: I18n.get('Management'),
  produce: I18n.get('Produce'),
  grades: I18n.get('Grades'),
  currencies: I18n.get('Currencies'),
  countries: I18n.get('Countries'),
  varieties: I18n.get('Varieties'),

  /* Sensor */
  calibration: I18n.get('Calibration'),

  // FYI
  fyi: I18n.get('FYI')
})

export default Strings
