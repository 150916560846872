import { useState } from 'react'
import Card from '@/primitives/Card'
import MetricsHeader from './MetricsHeader'
import HistoricalView from './HistoricalView'
import LiveView from './LiveView'
import { useDashboardMetrics } from './hooks/useDashboardMetrics'
import { useViewMode } from './hooks/useViewMode'
import Slot from '@/primitives/Slot'

const MetricsCard = ({
  type = 'envirosense',
  dashboardData,
  sectionA,
  sectionB,
  metrics,
  className = '',
  ...rest
}) => {
  const [selectedMetric, setSelectedMetric] = useState(metrics[0]?.id || '')
  const { viewMode, showDelta, toggleDelta, setViewMode } = useViewMode()

  const transformedData = useDashboardMetrics(
    dashboardData,
    sectionA?.id,
    sectionB?.id,
    metrics
  )

  const selectedMetricObj = metrics.find(m => m.id === selectedMetric)

  return (
    <Card className={className} {...rest}>
      <Slot name='header'>
        <MetricsHeader
          type={type}
          viewMode={viewMode}
          showDelta={showDelta}
          onDeltaToggle={toggleDelta}
          onViewModeChange={setViewMode}
          sectionAId={sectionA?.id}
          sectionBId={sectionB?.id}
        />
      </Slot>
      <Slot name='body'>
        {viewMode === 'historical' ? (
          <HistoricalView
            metrics={metrics}
            selectedMetric={selectedMetric}
            selectedMetricObj={selectedMetricObj}
            onMetricSelect={setSelectedMetric}
            transformedData={transformedData}
            showDelta={showDelta}
            sectionAName={sectionA?.name}
            sectionBName={sectionB?.name}
          />
        ) : (
          <LiveView
            metrics={metrics}
            transformedData={transformedData}
            sectionAName={sectionA?.name}
            sectionBName={sectionB?.name}
          />
        )}
      </Slot>
    </Card>
  )
}

export default MetricsCard
