import { useState } from 'react'

export const useViewMode = () => {
  const [viewMode, setViewMode] = useState('live')
  const [showDelta, setShowDelta] = useState(false)

  const toggleDelta = () => setShowDelta(!showDelta)

  return {
    viewMode,
    showDelta,
    setViewMode,
    toggleDelta
  }
}
