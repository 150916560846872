import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { fetchProduceThresholds } from '@/slices/operations/produceThreshold'

import {
  createProduce,
  requestProduce,
  updateProduce
} from '@/actions/operations/produce'

import {
  getOperationsProduce,
  getOperationsLoading,
  getOperationsError,
  getOperationsProduceThresholds
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Text, Loader, Select } from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formReducer, formInitalState } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.')),
  code: string().required(I18n.get('Please provide a code.')),
  produceThresholdId: string().nullable()
})

function ProduceForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const produce = getOperationsProduce()
  const loading = getOperationsLoading()
  const error = getOperationsError()
  const produceThresholds = getOperationsProduceThresholds()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (produceThresholds.length === 0) {
      dispatch(fetchProduceThresholds())
    }
  }, [])

  useEffect(() => {
    if (itemId && produce.id !== itemId) {
      dispatch(requestProduce({ produceId: itemId }))
    }
  }, [dispatch, produce?.id, itemId])

  useEffect(() => {
    if (produce?.id && produce.id === itemId) {
      dispatchFormState({ type: 'set-state', state: produce })
    }
  }, [produce, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      if (formState.produceThresholdId === '')
        formState.produceThresholdId = null
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.produceId = itemId
        dispatch(updateProduce(formState))
      } else {
        dispatch(createProduce(formState))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'update',
      name: 'organizationId',
      value: organizationId
    })
  }

  const onChange = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Produce')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Produce')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Code')}
            </Text>
            <Input value={formState.code} name='code' onChange={onChange} />
            <InputError error={errors?.code} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Default Threshold Template')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='produceThresholdId'
              value={formState.produceThresholdId}
              onChange={onChange}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {produceThresholds.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.produceThresholdId} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default ProduceForm
