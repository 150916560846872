import React from 'react'
import { I18n } from 'aws-amplify'
import Box from '@/primitives/Box'
import Text from '@/primitives/Text'

export const EmptyState = () => (
  <Box style={{ margin: '0.8rem 0' }}>
    <Text tone={800} variant='page'>
      {I18n.get('No trial data available')}
    </Text>
  </Box>
)
