import Box from '@/primitives/Box'
import Text from '@/primitives/Text'

const MetricValue = ({ label, value, unit = '' }) => {
  return (
    <Box>
      <Text size={100} variant='page' tone={500}>
        {label}
      </Text>
      <Text as='div' size={400} fontWeight={700}>
        {typeof value === 'number' ? value.toFixed(1) : '—'}
        {unit && (
          <Text as='span' size={100} variant='page' tone={500}>
            {unit}
          </Text>
        )}
      </Text>
    </Box>
  )
}

export default MetricValue
