import { I18n } from 'aws-amplify'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTrial, getTrials } from '@/api/management/trial'
import { showBanner } from '../util'

export const fetchTrials = createAsyncThunk(
  'fetchTrials',
  async (params, { dispatch }) => {
    try {
      return await getTrials(params)
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Error fetching trials')
        })
      )
      throw error
    }
  }
)

export const fetchTrial = createAsyncThunk(
  'fetchTrial',
  async (params, { dispatch }) => {
    try {
      return await getTrial(params)
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Error fetching trial')
        })
      )
      throw error
    }
  }
)

const trialsSlice = createSlice({
  name: 'managementTrialsReducer',
  initialState: {
    trials: [],
    trialsLoading: false,
    currentTrial: null,
    currentTrialLoading: false,
    error: null,
    envSensorsA: [],
    envSensorsB: [],
    soilSensorsA: [],
    soilSensorsB: []
  },
  reducers: {
    cleanTrials: state => {
      state.trials = []
    },
    cleanCurrentTrial: state => {
      state.currentTrial = null
    },
    setEnvSensors: (state, action) => {
      const { sectionA, sectionB } = action.payload
      state.envSensorsA = sectionA
      state.envSensorsB = sectionB
    },
    setSoilSensors: (state, action) => {
      const { sectionA, sectionB } = action.payload
      state.soilSensorsA = sectionA
      state.soilSensorsB = sectionB
    },
    cleanSelectedSensors: state => {
      state.envSensorsA = []
      state.envSensorsB = []
      state.soilSensorsA = []
      state.soilSensorsB = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTrials.pending, state => {
        state.trialsLoading = true
        state.error = null
      })
      .addCase(fetchTrials.fulfilled, (state, action) => {
        state.trials = action.payload
        state.trialsLoading = false
        state.error = null
      })
      .addCase(fetchTrials.rejected, (state, action) => {
        state.trials = []
        state.trialsLoading = false
        state.error = action.error
      })
      .addCase(fetchTrial.pending, state => {
        state.currentTrialLoading = true
        state.error = null
      })
      .addCase(fetchTrial.fulfilled, (state, action) => {
        state.currentTrial = action.payload
        state.currentTrialLoading = false
        state.error = null
      })
      .addCase(fetchTrial.rejected, (state, action) => {
        state.currentTrial = null
        state.currentTrialLoading = false
        state.error = action.error
      })
  }
})

export const {
  cleanTrials,
  cleanCurrentTrial,
  setEnvSensors,
  setSoilSensors,
  cleanSelectedSensors
} = trialsSlice.actions

export default trialsSlice.reducer
