import React from 'react'
import Box from '@/primitives/Box'
import Text from '@/primitives/Text'
import { STATUS_STYLES, TRIAL_STATUS } from './constants'
import strings from '../../Strings'

export const StatusBadge = ({ status }) => {
  status = status.toLowerCase()
  const style = STATUS_STYLES[status] || STATUS_STYLES[TRIAL_STATUS.PLANNED]

  return (
    <Box
      style={{
        padding: '4px 12px',
        borderRadius: '16px',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '8px'
      }}
    >
      <Box
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: style.dotColor
        }}
      />
      <Text style={{ color: style.textColor }} size={300} fontWeight={600}>
        {strings[status]}
      </Text>
    </Box>
  )
}

export default StatusBadge
