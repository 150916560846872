import { graphqlOperation } from 'aws-amplify'
import { makeGraphqlRequestV2 } from '../utils'
import { GRAPHQL_URL } from './config'

export const getTrials = async ({ zoneId }) => {
  const query = /* GraphQL */ `
    query getTrials($zoneId: String!) {
      getTrials(zoneId: $zoneId) {
        id
        name
        startDate
        endDate
        status
        zone {
          name
          id
        }
        roomA {
          id
          name
        }
        roomB {
          id
          name
        }
      }
    }
  `

  try {
    const operation = graphqlOperation(query, { zoneId })
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    if (!data?.getTrials) {
      throw new Error('Invalid response data')
    }

    return data.getTrials
  } catch (err) {
    throw new Error(`Failed to fetch trials: ${err.message}`)
  }
}

export const getTrial = async ({ id }) => {
  const query = /* GraphQL */ `
    query getTrials($id: String!) {
      getTrials(id: $id) {
        id
        name
        startDate
        endDate
        status
        zone {
          name
          id
        }
        roomA {
          id
          name
        }
        roomB {
          id
          name
        }
      }
    }
  `

  try {
    const operation = graphqlOperation(query, { id })
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getTrials[0] || null
  } catch (err) {
    throw new Error(`Failed to fetch trial: ${err.message}`)
  }
}
